import { useParams } from 'react-router';
// redux
import { useGetUpdatesQuery } from 'src/redux/api/updateApi';
// @types
import { PerformedUpdatesResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
import { TableHeader } from 'src/@types/layout';
// hooks
import useGravityTable from 'src/hooks/useGravityTable';
import useLocales from 'src/hooks/useLocales';
// utils
import { fShortDateTime } from 'src/utils/format';
// mock
import { DUMMY_UPDATES_DATA } from 'src/mock/sites';
// components
import { TableHeadCustom, TablePagination, TableSkeleton } from 'src/components/gravity/table';
//
import UpdateTableRow from './UpdateTableRow';

// ----------------------------------------------------------------------

const HEADERS: TableHeader[] = [
  {
    id: 'date',
    label: 'dashboard.sites.details.mwp.updates.updateListing.table.header.date',
  },
  {
    id: 'status',
    label: 'dashboard.sites.details.mwp.updates.updateListing.table.header.status',
  },
  {
    id: 'message',
    label: 'dashboard.sites.details.mwp.updates.updateListing.table.header.message',
  },
  {
    id: 'action',
    label: '',
  },
];

// ----------------------------------------------------------------------

export default function UpdateTable() {
  const { name } = useParams();

  // HOOK
  const { translate, currentLang } = useLocales();

  const {
    page,
    rowsPerPage,
    //
    onChangePage,
  } = useGravityTable({
    defaultRowsPerPage: 25,
  });

  // API
  const { data, isFetching } = useGetUpdatesQuery(name);

  // VAR
  // const filteredData: PerformedUpdatesResponse[] = data?.data.updateList || [];
  const filteredData: PerformedUpdatesResponse[] = DUMMY_UPDATES_DATA.updateList; // TESTING CODE

  const isNotFound = filteredData.length === 0;

  return (
    <div className="gv-data-table">
      <div style={{ overflowX: 'auto' }}>
        <table className={!isFetching && isNotFound ? '' : 'gv-col-2-shrink gv-col-4-shrink'}>
          <TableHeadCustom headers={HEADERS} />
          <tbody>
            {!isFetching
              ? filteredData
                  .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                  .map((row) => <UpdateTableRow key={row.id} row={row} />)
              : [...Array(rowsPerPage)].map((_, index) => (
                  <TableSkeleton key={index} headers={HEADERS} />
                ))}
          </tbody>
        </table>
      </div>

      {!isFetching && isNotFound && (
        <div className="gv-empty-state">
          <div className="gv-empty-state-content">
            <h3 className="gv-title">
              {translate('dashboard.sites.details.mwp.updates.updateListing.table.noData.title')}
            </h3>
            <p>
              {data?.data.nextUpdate
                ? translate(
                    'dashboard.sites.details.mwp.updates.updateListing.table.noData.description.nextUpdateAvailable',
                    {
                      dateTime: fShortDateTime(new Date(data.data.nextUpdate), {
                        locale: currentLang.value,
                      }),
                    }
                  )
                : translate(
                    'dashboard.sites.details.mwp.updates.updateListing.table.noData.description.nextUpdateNotAvailable'
                  )}
            </p>
          </div>
        </div>
      )}

      <TablePagination
        totalRows={filteredData.length}
        currentPage={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
      />
    </div>
  );
}
