import { useParams, useNavigate } from 'react-router';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// redux
import { dispatch } from 'src/redux/store';
import { userApi } from 'src/redux/api/userApi';
import { useDeleteDomainMutation } from 'src/redux/api/domainApi';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { fTranslationDynamicKey } from 'src/utils/format';
import { displayToast } from 'src/utils/handleToast';
// components
import { TextCustomize } from 'src/components/gravity/text';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import { FormProvider, RHFTextField } from 'src/components/gravity/hook-form';
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type FormValuesProps = {
  domainName: string;
  afterSubmit?: string;
};

type Props = {
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteDomainConfirmation({ onClose }: Props) {
  const { name } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [deleteDomain, { isLoading }] = useDeleteDomainMutation();

  // FORM
  const FormSchema = Yup.object().shape({
    domainName: Yup.string()
      .required(
        translate('wpone.domains.details.settings.deleteDomainDialog.inputField.validation1')
      )
      .test(
        'domain-name',
        translate('wpone.domains.details.settings.deleteDomainDialog.inputField.validation2'),
        function (value) {
          if (value === name) {
            return true;
          }
          return false;
        }
      ),
  });

  const defaultValues = {
    domainName: '',
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  // EVENT FUNCTION
  const onSubmit = () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: `delete-domain`,
    });

    deleteDomain(name)
      .unwrap()
      .then((data) => {
        if (data?.success) {
          displayToast(
            translate(
              'wpone.domains.details.settings.deleteDomainDialog.requestResponse.successMessage'
            )
          );
          // For getting latest update on Customer table (for support/service)
          dispatch(userApi.util.invalidateTags([{ type: 'User', id: 'PARTIAL-LIST' }]));
          onClose();
          navigate('/dns');
        } else {
          if (isMountedRef.current) {
            setError('afterSubmit', {
              message: translate('wpone.general.requestResponse.errorMessage.other'),
            });
          } else {
            displayToast(translate('wpone.general.requestResponse.errorMessage.other'), {
              variant: 'alert',
            });
          }
        }
      })
      .catch((error) => {
        // Error message check order: domain has sites => general
        const errCode = (error as { status: number; data: any }).status;
        const errMsg = (error as { status: number; data: any }).data.message;

        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(
              [
                `wpone.domains.details.settings.deleteDomainDialog.requestResponse.errorMessage.${fTranslationDynamicKey(
                  errMsg
                )}`,
                convertStatusCode(errCode),
              ],
              { domainName: name }
            ),
          });
        } else {
          displayToast(
            translate(
              [
                `wpone.domains.details.settings.deleteDomainDialog.requestResponse.errorMessage.${fTranslationDynamicKey(
                  errMsg
                )}`,
                convertStatusCode(errCode),
              ],
              { domainName: name }
            ),
            { variant: 'alert' }
          );
        }
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.domains.details.settings.deleteDomainDialog.title')}
        </h2>

        <FormProvider methods={methods}>
          <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
            {!!errors.afterSubmit?.message && (
              <Alert type="alert" text={errors.afterSubmit.message} />
            )}

            <TextCustomize
              text={translate('wpone.domains.details.settings.deleteDomainDialog.description', {
                domainName: name,
              })}
            />
            <p>{translate('wpone.domains.details.settings.deleteDomainDialog.confirmation')}</p>

            <RHFTextField
              name="domainName"
              label={translate(
                'wpone.domains.details.settings.deleteDomainDialog.inputField.label'
              )}
              labelId="main-domain"
            />
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.domains.details.settings.deleteDomainDialog.action')}
          uiType="destructive"
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
