import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { dispatch } from 'src/redux/store';
import { userApi } from 'src/redux/api/userApi';
import { useTrasferSiteOwnershipMutation } from 'src/redux/api/resourceApi';
// form
import { useForm } from 'react-hook-form';
// @types
import { AccessPermissionFullDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFCheckbox } from 'src/components/gravity/hook-form';
import { TextWithBoldDynamicContent } from 'src/components/gravity/text';
import Alert from 'src/components/gravity/Alert';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type FormValuesProps = {
  removeCurrentOwner: boolean;
  zoneOwnershipChange: boolean;
  afterSubmit?: string;
};

type Props = {
  onClose: VoidFunction;
  accessPermissionInfo: AccessPermissionFullDTO | null;
  ownerAccessPermissionInfo: AccessPermissionFullDTO | null;
};

// ----------------------------------------------------------------------

export default function TransferOwnershipForm({
  accessPermissionInfo,
  ownerAccessPermissionInfo,
  onClose,
}: Props) {
  const { cluster, namespace, name } = useParams();

  // HOOK
  const { translate } = useLocales();

  // API
  const [transferSiteOwnership, { isLoading }] = useTrasferSiteOwnershipMutation();

  // FORM
  const defaultValues = {
    zoneOwnershipChange: false,
    removeCurrentOwner: false,
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (!cluster || !namespace) return;

    if (ownerAccessPermissionInfo === null) {
      displayToast(translate('wpone.general.requestResponse.errorMessage.other'), {
        variant: 'alert',
      });
      return;
    }

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'transfer-site-ownership',
    });

    transferSiteOwnership({
      siteId: `${cluster}/${namespace}`,
      transferData: {
        recipient: accessPermissionInfo?.user.email as string,
        remove_current: data.removeCurrentOwner,
        propagate: data.zoneOwnershipChange,
      },
    })
      .unwrap()
      .then(() => {
        displayToast(
          translate('wpone.general.requestResponse.access.successMessage.transferAccess')
        );
        // For getting latest update on Customer table
        dispatch(userApi.util.invalidateTags([{ type: 'User', id: 'PARTIAL-LIST' }]));
        onClose();
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        onClose();
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.users.transferOwnershipDialog.title', {
            email: accessPermissionInfo?.user.email,
          })}
        </h2>

        <FormProvider methods={methods}>
          <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
            <TextWithBoldDynamicContent
              text={translate('wpone.sites.details.users.transferOwnershipDialog.description', {
                email: accessPermissionInfo?.user.email,
              })}
            />

            <RHFCheckbox
              name="removeCurrentOwner"
              labelId="remove-current-owner"
              label={translate(
                'wponesupport.sites.details.users.transferOnwershipDialog.removeCurrentOwnerAccess'
              )}
              condensed
            />

            <RHFCheckbox
              name="zoneOwnershipChange"
              labelId="zone-ownership-change"
              label={translate(
                'wponesupport.sites.details.users.transferOnwershipDialog.zoneOwnershipChange',
                {
                  domain: name,
                }
              )}
              condensed
            />

            <Alert
              type="warning"
              text={translate('wpone.sites.details.users.transferOwnershipDialog.warning')}
            />
          </div>
        </FormProvider>
      </div>
      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.sites.details.users.transferOwnershipDialog.action')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
