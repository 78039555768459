// @types
import { AccessPermissionGetListResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import ResetSiteForm from './ResetSiteForm';

// ----------------------------------------------------------------------

type Props = {
  siteOwner: AccessPermissionGetListResponse | undefined;
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function ResetSiteDialog({ siteOwner, open, onClose }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<ResetSiteForm siteOwner={siteOwner} onClose={onClose} />}
    />
  );
}
