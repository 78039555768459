type Props = {
  type?: 'alert' | 'success' | 'warning';
  value: number;
};

// ----------------------------------------------------------------------

export default function DonutChart({ type, value }: Props) {
  // HELPER FUNCTION
  function convertToNearestSupportedValue(value: number): number {
    const supportedValues = [0, 20, 40, 60, 80, 100];
    return supportedValues.reduce((prev, curr) =>
      Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
    );
  }

  return (
    <div className="gv-donut-chart">
      <div
        className={`gv-progress-circle${type ? ` gv-state-${type}` : ''}`}
        data-progress-percent={convertToNearestSupportedValue(value)}
        role="meter"
        aria-valuenow={value}
        aria-valuemin={0}
        aria-valuemax={100}
      >
        <gv-indicator src="/src/indicators/progress-circle.svg" />
        <span>{!type ? '-' : value}</span>
      </div>
    </div>
  );
}
