// @types
import {
  PerformanceScoreGeneralInfo,
  LighthouseScoreEnum,
  LighthouseStatusEnum,
} from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { convertLighthouseValueToStatus } from 'src/utils/convert';
// components
import { TextCustomize } from 'src/components/gravity/text';
import Button from 'src/components/gravity/Button';
import Accordion from 'src/components/gravity/Accordion';
import DonutChart from 'src/components/gravity/DonutChart';
//
import PerformanceSubScores from './PerformanceSubScores';

// ----------------------------------------------------------------------

const RECOMMENDATIONS_ACCORDION_INFO: {
  type: LighthouseScoreEnum;
  recommendations: {
    title: string;
    tileName?: string;
    description: string;
    action?: {
      title: string;
      link: string;
    };
  }[];
}[] = [
  {
    type: LighthouseScoreEnum.PERFORMANCE,
    recommendations: [
      {
        title:
          'dashboard.sites.details.mwp.performance.block.performance.recommendations.getWpRocket.title',
        tileName: 'wp-plugin-wp-rocket',
        description:
          'dashboard.sites.details.mwp.performance.block.performance.recommendations.getWpRocket.description',
        action: {
          title:
            'dashboard.sites.details.mwp.performance.block.performance.recommendations.getWpRocket.action',
          link: 'https://wp-rocket.me/',
        },
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.performance.recommendations.activateCdn.title',
        description:
          'dashboard.sites.details.mwp.performance.block.performance.recommendations.activateCdn.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.performance.recommendations.deleteUnusedPlugins.title',
        description:
          'dashboard.sites.details.mwp.performance.block.performance.recommendations.deleteUnusedPlugins.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.performance.recommendations.useLazyLoad.title',
        description:
          'dashboard.sites.details.mwp.performance.block.performance.recommendations.useLazyLoad.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.performance.recommendations.optimiseDb.title',
        description:
          'dashboard.sites.details.mwp.performance.block.performance.recommendations.optimiseDb.description',
      },
    ],
  },
  {
    type: LighthouseScoreEnum.SEO,
    recommendations: [
      {
        title:
          'dashboard.sites.details.mwp.performance.block.seo.recommendations.getRankMath.title',
        tileName: 'wp-plugin-rankmath',
        description:
          'dashboard.sites.details.mwp.performance.block.seo.recommendations.getRankMath.description',
        action: {
          title:
            'dashboard.sites.details.mwp.performance.block.seo.recommendations.getRankMath.action',
          link: 'https://rankmath.com/',
        },
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.seo.recommendations.updateContent.title',
        description:
          'dashboard.sites.details.mwp.performance.block.seo.recommendations.updateContent.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.seo.recommendations.includeKeywords.title',
        description:
          'dashboard.sites.details.mwp.performance.block.seo.recommendations.includeKeywords.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.seo.recommendations.fixBrokenLinks.title',
        description:
          'dashboard.sites.details.mwp.performance.block.seo.recommendations.fixBrokenLinks.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.seo.recommendations.useBacklinks.title',
        description:
          'dashboard.sites.details.mwp.performance.block.seo.recommendations.useBacklinks.description',
      },
    ],
  },
  {
    type: LighthouseScoreEnum.ACCESSIBILITY,
    recommendations: [
      {
        title:
          'dashboard.sites.details.mwp.performance.block.accessibility.recommendations.addAltText.title',
        description:
          'dashboard.sites.details.mwp.performance.block.accessibility.recommendations.addAltText.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.accessibility.recommendations.ensureColorContrast.title',
        description:
          'dashboard.sites.details.mwp.performance.block.accessibility.recommendations.ensureColorContrast.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.accessibility.recommendations.followClearHeadingHierachy.title',
        description:
          'dashboard.sites.details.mwp.performance.block.accessibility.recommendations.followClearHeadingHierachy.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.accessibility.recommendations.makeClickableLargeEnough.title',
        description:
          'dashboard.sites.details.mwp.performance.block.accessibility.recommendations.makeClickableLargeEnough.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.accessibility.recommendations.highlightLinks.title',
        description:
          'dashboard.sites.details.mwp.performance.block.accessibility.recommendations.highlightLinks.description',
      },
    ],
  },
  {
    type: LighthouseScoreEnum.BEST_PRACTICES,
    recommendations: [
      {
        title:
          'dashboard.sites.details.mwp.performance.block.bestPractices.recommendations.updateRegularly.title',
        description:
          'dashboard.sites.details.mwp.performance.block.bestPractices.recommendations.updateRegularly.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.bestPractices.recommendations.useSslHttps.title',
        description:
          'dashboard.sites.details.mwp.performance.block.bestPractices.recommendations.useSslHttps.description',
      },
      {
        title:
          'dashboard.sites.details.mwp.performance.block.bestPractices.recommendations.optimiseImages.title',
        description:
          'dashboard.sites.details.mwp.performance.block.bestPractices.recommendations.optimiseImages.description',
      },
    ],
  },
];

// ----------------------------------------------------------------------

type Props = {
  blockInfo: PerformanceScoreGeneralInfo;
  score: number | undefined;
};

// ----------------------------------------------------------------------

export default function PerformanceBlock({ blockInfo, score }: Props) {
  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // VAR
  const status = convertLighthouseValueToStatus(blockInfo.type, score);

  return (
    <div
      id={blockInfo.type}
      className="gv-card"
      style={{ background: 'var(--color-surface-bright)' }}
    >
      <div className="gv-card-content">
        <div className="gv-flex-column-lg">
          <div
            className={`gv-flex-column-md gv-tab-flex-row ${
              isMobile ? 'gv-items-start' : 'gv-items-center'
            }`}
          >
            <DonutChart
              type={
                status === LighthouseStatusEnum.unknown
                  ? undefined
                  : status === LighthouseStatusEnum.good
                  ? 'success'
                  : status
              }
              value={score !== undefined ? score * 100 : 0}
            />
            <div className="gv-flex gv-flex-col">
              <p className="gv-text-sm gv-text-bold">
                {translate(`dashboard.sites.details.mwp.performance.block.${blockInfo.type}.title`)}
              </p>
              <p className="gv-text-sm gv-text-on-alternative">
                {translate(blockInfo.description[status])}
              </p>
            </div>
          </div>

          {blockInfo.type === LighthouseScoreEnum.PERFORMANCE && (
            <>
              <img
                src="/assets/mwp/site_performance_placeholder.svg"
                alt="site_performance_placeholder"
                style={{ width: '100%', objectFit: 'cover' }}
              />

              <PerformanceSubScores />
            </>
          )}

          {(status === LighthouseStatusEnum.warning || status === LighthouseStatusEnum.alert) && (
            <div className="gv-flex-column-md">
              <p className="gv-text-lg gv-text-bold">
                {translate('dashboard.sites.details.mwp.performance.block.recommendations')}
              </p>

              {RECOMMENDATIONS_ACCORDION_INFO.filter(
                (accordion) => accordion.type === blockInfo.type
              )
                // Only one item left after filtered
                .map((accordion, index) => (
                  <Accordion
                    key={index}
                    style={{ backgroundColor: 'var(--color-surface-dim)', paddingInline: '24px' }}
                    openByDefault={
                      accordion.type === LighthouseScoreEnum.PERFORMANCE ||
                      accordion.type === LighthouseScoreEnum.SEO
                        ? 0
                        : undefined
                    }
                    accordionItems={accordion.recommendations.map((recommendation) => ({
                      title: recommendation.title,
                      iconPath: recommendation.tileName,
                      content: (
                        <div className="gv-flex-column-md">
                          <div className="gv-flex-column-sm">
                            {recommendation.tileName && (
                              <gv-tile
                                src={`/src/tiles/${recommendation.tileName}.svg`}
                                size="sm"
                              />
                            )}
                            <TextCustomize
                              text={translate(recommendation.description)}
                              // Only for Best pratices/Use SSL/HTTPS recommendation
                              linkAction={[
                                () =>
                                  window.open(
                                    'https://help.one.com/hc/en-us/articles/115005594065-Use-HTTPS-on-your-WordPress-site',
                                    '_blank'
                                  ),
                              ]}
                              linkStyle={{
                                cursor: 'pointer',
                                fontWeight: 600,
                              }}
                            />
                          </div>
                          {recommendation.action && (
                            <div>
                              <Button
                                text={translate(recommendation.action.title)}
                                onClick={() => window.open(recommendation.action?.link, '_blank')}
                                style={{ width: 'auto' }}
                              />
                            </div>
                          )}
                        </div>
                      ),
                    }))}
                  />
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
