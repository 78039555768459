import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router';
// redux
import { useDispatch } from 'src/redux/store';
import { setTabConfig } from 'src/redux/features/layout';
// config
import { PAGE_DETAILS_TAB_CONFIG } from 'src/config';
// components
import { SidebarPage } from 'src/components/gravity/page';

// ----------------------------------------------------------------------

export default function DnsDetailsLayout() {
  const { name } = useParams();

  const dispatch = useDispatch();

  //
  useEffect(() => {
    dispatch(setTabConfig(PAGE_DETAILS_TAB_CONFIG.dns));

    return () => {
      dispatch(setTabConfig(undefined));
    };
  }, [dispatch]);

  return (
    <SidebarPage title={`${name}`}>
      <Outlet />
    </SidebarPage>
  );
}
