import { Dispatch, SetStateAction } from 'react';
// @types
import { SecuritySettings } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

type Props = {
  settings: SecuritySettings;
  onClose: VoidFunction;
  setSettings: Dispatch<SetStateAction<SecuritySettings>>;
  setIsSettingsLoading: Dispatch<SetStateAction<boolean>>;
};

// ----------------------------------------------------------------------

export default function RestoreDefaultSettingsConfirmation({
  settings,
  onClose,
  setSettings,
  setIsSettingsLoading,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  // EVENT FUNCTION
  function handleRestoreDefaultSettings() {
    setIsSettingsLoading(true);
    onClose();

    const defaultSettings: SecuritySettings = {
      autoUpdate: true,
      notifications: {
        newVulns: true,
        fixedVulns: true,
      },
    };

    if (
      settings.autoUpdate === defaultSettings.autoUpdate &&
      settings.notifications.newVulns === defaultSettings.notifications.newVulns &&
      settings.notifications.fixedVulns === defaultSettings.notifications.fixedVulns
    ) {
      displayToast(
        translate(
          'dashboard.sites.details.mwp.security.settings.restoreDefaultSettingsDialog.toast.info'
        ),
        { variant: 'info' }
      );
      setIsSettingsLoading(false);
    } else {
      setTimeout(() => {
        const isSuccess = false;

        if (isSuccess) {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.security.settings.restoreDefaultSettingsDialog.toast.success'
            )
          );
          setSettings({
            autoUpdate: true,
            notifications: {
              newVulns: true,
              fixedVulns: true,
            },
          });
        } else {
          displayToast(
            translate(
              'dashboard.sites.details.mwp.security.settings.restoreDefaultSettingsDialog.toast.error'
            ),
            { variant: 'alert' }
          );
        }

        setIsSettingsLoading(false);
      }, 2000);
    }
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate(
            'dashboard.sites.details.mwp.security.settings.restoreDefaultSettingsDialog.title'
          )}
        </h2>

        <p style={{ overflowX: 'auto' }}>
          {translate(
            'dashboard.sites.details.mwp.security.settings.restoreDefaultSettingsDialog.description'
          )}
        </p>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button
          text={translate('dashboard.general.action.cancel')}
          uiType="cancel"
          onClick={onClose}
        />
        <Button
          text={translate(
            'dashboard.sites.details.mwp.security.settings.restoreDefaultSettingsDialog.action'
          )}
          onClick={handleRestoreDefaultSettings}
        />
      </ButtonGroup>
    </>
  );
}
