import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { useSelector } from 'src/redux/store';
import { useAddDefaultDnsRecordsMutation, useGetDnsRecordsQuery } from 'src/redux/api/domainApi';
// @types
import {
  DeletingRecordsState,
  DnsRecordRowProps,
  DnsRecordType,
  FormattedGetDNSRecordDTO,
} from 'src/@types/domain';
import { TableHeader } from 'src/@types/layout';
// hooks
import useLocales from 'src/hooks/useLocales';
import useGravityTable from 'src/hooks/useGravityTable';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import { TableHeadCustom, TablePagination, TableSkeleton } from 'src/components/gravity/table';
import Button from 'src/components/gravity/Button';
import Alert from 'src/components/gravity/Alert';
//
import DnsRecordTableToolbar from './DnsRecordTableToolbar';
import DnsRecordTableRow from './DnsRecordTableRow';

// ----------------------------------------------------------------------

const TABLE_HEAD: TableHeader[] = [
  {
    id: 'type',
    label: 'wpone.domains.details.dnsRecords.table.header.type',
  },
  {
    id: 'connection',
    label: 'wpone.domains.details.dnsRecords.table.header.connectionMode',
  },
  {
    id: 'domain',
    label: 'wpone.domains.details.dnsRecords.table.header.domain',
  },
  {
    id: 'content',
    label: 'wpone.domains.details.dnsRecords.table.header.content',
  },
  {
    id: 'action',
    label: '',
  },
];

// ----------------------------------------------------------------------

type Props = {
  isBunnyDns: boolean;
  setDeletingRecordsState: Dispatch<SetStateAction<DeletingRecordsState>>;
  onOpenDeleteDnsRecordDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DnsRecordTable({
  isBunnyDns,
  setDeletingRecordsState,
  onOpenDeleteDnsRecordDialog,
}: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const { page, rowsPerPage, setPage, onChangePage, onChangeRowsPerPage } = useGravityTable({
    defaultRowsPerPage: 10,
  });

  const isMountedRef = useIsMountedRef();

  // API
  const { data, isFetching } = useGetDnsRecordsQuery(name);

  const [addDefaultDnsRecords, { isLoading: addDefaultDnsRecordsIsLoading }] =
    useAddDefaultDnsRecordsMutation();

  // STATE
  const { user } = useSelector((state) => state.auth);

  const [filterType, setFilterType] = useState<DnsRecordType | 'all'>('all');

  const [tableData, setTableData] = useState<DnsRecordRowProps[]>([]);

  // Handle status banner using local state since the action and banner is in the same page
  const [addStandardRecordSuccess, setAddStandardRecordSuccess] = useState<boolean | undefined>(
    undefined
  );

  // VAR
  const isNotFound = !tableData.length && filterType !== 'all';

  const isNoData = !tableData.length && filterType === 'all';

  // EVENT FUNCTION
  function handleToolbarFilterType(type: DnsRecordType | 'all') {
    setFilterType(type);
    setPage(1);
  }

  function handleToolbarDeleteRecord() {
    const selectedRecords: FormattedGetDNSRecordDTO[] = tableData
      .filter((row) => row.selected)
      .map((row) => row.record);

    setDeletingRecordsState({
      records: selectedRecords,
      allSelected: selectedRecords.length === tableData.length,
    });
    onOpenDeleteDnsRecordDialog();
  }

  function handleSelectAllRows(checked: boolean) {
    setTableData((prev) =>
      prev.map((row) =>
        filterType === 'all' || filterType === row.record.type ? { ...row, selected: checked } : row
      )
    );
  }

  function handleSelectRow(id: string, checked: boolean) {
    setTableData((prev) =>
      prev.map((row) => (row.record.id === id ? { ...row, selected: checked } : row))
    );
  }

  function handleDeleteRow(selectedRecord: FormattedGetDNSRecordDTO) {
    setDeletingRecordsState({
      records: [selectedRecord],
      allSelected: tableData.length === 1,
    });
    onOpenDeleteDnsRecordDialog();
  }

  function handleAddStandardDns() {
    if (!name || !user?.brand?.name) return;

    setAddStandardRecordSuccess(undefined);

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'add-standard-record',
    });

    addDefaultDnsRecords({
      domain: name,
      brand: user.brand.name,
    })
      .unwrap()
      .then((data) => {
        if (data.success) {
          if (isMountedRef.current) {
            setAddStandardRecordSuccess(true);
          } else {
            displayToast(
              translate('wpone.domains.details.dnsRecords.table.noData.requestResponse.success')
            );
          }
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        if (isMountedRef.current) {
          setAddStandardRecordSuccess(false);
        } else {
          displayToast(
            translate('wpone.domains.details.dnsRecords.table.noData.requestResponse.error'),
            {
              variant: 'alert',
            }
          );
        }
      });
  }

  // HELPER FUNCTION
  function filterRecordsType(
    tableData: FormattedGetDNSRecordDTO[] | undefined,
    filterType: string
  ): DnsRecordRowProps[] {
    if (filterType !== 'all') {
      tableData = tableData?.filter((item) => item.type === filterType);
    }

    return (
      tableData?.map((record) => ({
        selected: false,
        record,
      })) || []
    );
  }

  //
  // Prevent no data after deleting record
  useEffect(() => {
    const currentPageRows = tableData.slice(
      page * rowsPerPage - rowsPerPage,
      page * rowsPerPage
    ).length;

    if (currentPageRows === 0 && page !== 1) {
      setPage(1);
    }
  }, [data, isNotFound, page, rowsPerPage, setPage, tableData]);

  useEffect(() => {
    if (!isFetching && data) {
      setTableData(filterRecordsType(data, filterType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, filterType]);

  return (
    <div className="gv-flex-column-lg">
      {addStandardRecordSuccess !== undefined && (
        <Alert
          type={addStandardRecordSuccess ? 'success' : 'alert'}
          text={translate(
            `wpone.domains.details.dnsRecords.table.noData.requestResponse.${
              addStandardRecordSuccess ? 'success' : 'error'
            }`
          )}
        />
      )}

      <div className="gv-flex-column-md">
        {(isFetching || !isNoData) && (
          <DnsRecordTableToolbar
            tableData={tableData}
            handleToolbarFilterType={handleToolbarFilterType}
            handleToolbarDeleteRecord={handleToolbarDeleteRecord}
          />
        )}

        <div className="gv-data-table">
          <div className="data-table-scroll-handler" style={{ overflowX: 'auto' }}>
            <table className="gv-col-1-shrink">
              <TableHeadCustom
                headers={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={tableData.filter((row) => row.selected).length}
                onSelectAllRows={handleSelectAllRows}
              />
              <tbody>
                {!isFetching
                  ? tableData
                      .slice(page * rowsPerPage - rowsPerPage, page * rowsPerPage)
                      .map((row) => (
                        <DnsRecordTableRow
                          key={row.record.id}
                          isBunnyDns={isBunnyDns}
                          row={row}
                          handleSelectRow={handleSelectRow}
                          handleDeleteRow={handleDeleteRow}
                        />
                      ))
                  : [...Array(rowsPerPage)].map((_, index) => (
                      <TableSkeleton key={index} checkbox headers={TABLE_HEAD} />
                    ))}
              </tbody>
            </table>
          </div>

          {!isFetching && (isNotFound || isNoData) && (
            <div className="gv-empty-state">
              <div className="gv-empty-state-content">
                <p className="gv-text-lg gv-text-bold">
                  {translate(
                    `wpone.domains.details.dnsRecords.table.${
                      isNoData ? 'noData' : 'notFound'
                    }.title`
                  )}
                </p>

                <div className="gv-flex-column-md">
                  <p>
                    {isNoData
                      ? translate('wpone.domains.details.dnsRecords.table.noData.description')
                      : translate('wpone.domains.details.dnsRecords.table.notFound.description', {
                          type: filterType,
                        })}
                  </p>
                  {isNoData && (
                    <div>
                      <Button
                        condensed
                        startIcon="add"
                        text={translate('wpone.domains.details.dnsRecords.table.noData.action')}
                        disabled={addDefaultDnsRecordsIsLoading}
                        onClick={handleAddStandardDns}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {!isFetching && !isNoData && !isNotFound && (
            <TablePagination
              totalRows={tableData.length || 0}
              currentPage={page}
              onChangePage={onChangePage}
              rowsPerPage={rowsPerPage}
              changeRowsPerPageText={translate(
                'wpone.domains.details.dnsRecords.table.pagination.viewRowsPerPage'
              )}
              onChangeRowsPerPage={onChangeRowsPerPage}
            />
          )}
        </div>
      </div>
    </div>
  );
}
