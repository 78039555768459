import { Dispatch, SetStateAction } from 'react';
import { useParams } from 'react-router';
// @types
import {
  BackupSettings,
  BackupTableRowProps,
  RestoreEnvironmentEnum,
  RestoreMethodEnum,
} from 'src/@types/site';
import { LocaleEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertToIDN } from 'src/utils/convert';
import { streamingDownload } from 'src/utils/download';
import { displayToast } from 'src/utils/handleToast';
// config
import { HOST_API } from 'src/config';
// components
import { TableMoreMenu } from 'src/components/gravity/table';

// ----------------------------------------------------------------------

type Props = {
  row: BackupTableRowProps;
  isRestoring: boolean;
  setBackupSettings: Dispatch<SetStateAction<BackupSettings | null>>;
  onOpenRestoreBackupDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function BackupsTableRow2({
  row,
  isRestoring,
  setBackupSettings,
  onOpenRestoreBackupDialog,
}: Props) {
  const { name, cluster, namespace } = useParams();

  // HOOK
  const { translate, currentLang } = useLocales();

  // EVENT FUNCTION
  function handleOpenRestoreBackupDialog(env: RestoreEnvironmentEnum) {
    setBackupSettings({
      cluster: cluster as string,
      date: row.date,
      namespace: namespace as string,
      hostname: name as string,
      env: env,
      databaseBackupFileName: row.fileName,
      method: row.available,
    });
    onOpenRestoreBackupDialog();
  }

  function handleDownloadBackupFile() {
    const searchParams = new URLSearchParams();
    searchParams.append('cluster', cluster as string);
    searchParams.append('timestamp', (row.date.getTime() / 1000).toString());
    searchParams.append('namespace', namespace as string);
    const searchParamsString = searchParams.toString();

    const url = `/api/v5/backup/north1/download/${convertToIDN(
      name as string
    )}?${searchParamsString}`;
    const rootUrl = HOST_API || new URL(window.location.href).origin;

    // BACKUP STAGING TEST
    // url = `/api/v5/backup/north1/download/norvanera.fi?cluster=wp-cloud-europe-north1-a-7&timestamp=${timestamp}&namespace=norvanera-fi`;

    streamingDownload(`${rootUrl}${url}`, 'file-backup.zip');

    displayToast(translate('wpone.sites.details.backups.toast.startDownloadingBackup'), {
      variant: 'info',
    });
  }

  function handleDownloadBackupDb() {
    const url = `/api/v5/backup/download-database/${convertToIDN(name as string)}/${row.fileName}`;
    const rootUrl = HOST_API || new URL(window.location.href).origin;

    // BACKUP STAGING TEST
    // url = `/api/v5/backup/download-database/norvanera.fi/${fileName}`;

    streamingDownload(`${rootUrl}${url}`, 'database-backup.sql');

    displayToast(translate('wpone.sites.details.backups.toast.startDownloadingBackup'), {
      variant: 'info',
    });
  }

  return (
    <tr>
      <td>
        {new Intl.DateTimeFormat(
          currentLang.value === LocaleEnum.en ? 'en-GB' : currentLang.value,
          {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          }
        ).format(row.date)}
      </td>
      <td>
        {translate(`wpone.sites.details.backups.backupMethod.${row.available}`)}
        {(row.available === RestoreMethodEnum.files ||
          row.available === RestoreMethodEnum.database) && (
          <div className="gv-underline">
            {translate(
              `wpone.sites.details.backups.table.row.availabilityDescription.${row.available}`
            )}
          </div>
        )}
      </td>
      <td>{translate(`wpone.sites.details.backups.table.row.type.${row.type}`)}</td>
      <td>
        <TableMoreMenu
          sections={[
            {
              items: [
                {
                  icon: 'web',
                  text: translate('wpone.sites.details.backups.table.row.moreMenu.production'),
                  function: () => handleOpenRestoreBackupDialog(RestoreEnvironmentEnum.production),
                },
                {
                  icon: 'layers',
                  text: translate('wpone.sites.details.backups.table.row.moreMenu.staging'),
                  function: () => handleOpenRestoreBackupDialog(RestoreEnvironmentEnum.staging),
                },
                {
                  icon: 'folder_zip',
                  text: translate('wpone.sites.details.backups.table.row.moreMenu.filesDownload'),
                  function: handleDownloadBackupFile,
                },
                {
                  icon: 'download',
                  text: translate('wpone.sites.details.backups.table.row.moreMenu.dbDownload'),
                  function: handleDownloadBackupDb,
                },
              ]
                .filter((action) => {
                  if (isRestoring) return action.icon !== 'web' && action.icon !== 'layers';
                  return true;
                })
                .filter((action) => {
                  if (row.available === RestoreMethodEnum.files) return action.icon !== 'download';
                  if (row.available === RestoreMethodEnum.database)
                    return action.icon !== 'folder_zip';
                  return true;
                }),
            },
          ]}
        />
      </td>
    </tr>
  );
}
