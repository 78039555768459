import { useNavigate, useParams } from 'react-router';
// @types
import { DnsRecordRowProps, FormattedGetDNSRecordDTO } from 'src/@types/domain';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import Badge from 'src/components/gravity/Badge';
import { TableMoreMenu } from 'src/components/gravity/table';
import { fDnsRecordSubname } from 'src/utils/format';

// ----------------------------------------------------------------------

type Props = {
  isBunnyDns: boolean;
  row: DnsRecordRowProps;
  handleSelectRow: (id: string, checked: boolean) => void;
  handleDeleteRow: (selectedRecord: FormattedGetDNSRecordDTO) => void;
};

// ----------------------------------------------------------------------

export default function DnsRecordTableRow({
  isBunnyDns,
  row,
  handleSelectRow,
  handleDeleteRow,
}: Props) {
  const { name } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  // VAR
  const { selected, record } = row;

  return (
    <tr>
      <td>
        <input
          type="checkbox"
          className="gv-checkbox"
          checked={selected}
          onChange={(e) => handleSelectRow(record.id, e.target.checked)}
        />
      </td>
      <td>{record.type}</td>
      <td>
        <Badge
          type={record.proxied ? 'success' : 'generic'}
          text={translate(
            `wpone.domains.details.dnsRecords.table.row.connectionMode.${
              record.proxied ? 'proxied' : 'dnsOnly'
            }`
          )}
        />
      </td>
      <td>{fDnsRecordSubname(record.name, name, isBunnyDns) || name}</td>
      <td>
        <p>{record.content}</p>
        {record.priority !== undefined && (
          <p className="gv-caption-sm gv-text-on-alternative">
            {translate('wpone.domains.details.dnsRecords.table.row.content', {
              priority: record.priority,
            })}
          </p>
        )}
      </td>
      <td>
        <TableMoreMenu
          sections={[
            {
              items: [
                {
                  icon: 'edit',
                  text: translate('wpone.domains.details.dnsRecords.table.row.moreMenu.edit'),
                  function: () => navigate(`/dns/records/${name}/${record.id}/edit`),
                },
                {
                  icon: 'delete',
                  text: translate('wpone.domains.details.dnsRecords.table.row.moreMenu.delete'),
                  function: () => handleDeleteRow(row.record),
                },
              ],
            },
          ]}
        />
      </td>
    </tr>
  );
}
