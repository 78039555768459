import { ChangeEvent, ReactNode } from 'react';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  totalRows: number;
  currentPage: number;
  rowsPerPage?: number;
  rowsPerPageOptions?: number[];
  changeRowsPerPageText?: string;
  showInfo?: boolean;
  customInfo?: ReactNode;
  //
  onChangePage: (newPage: number) => void;
  onChangeRowsPerPage?: (event: ChangeEvent<HTMLSelectElement>) => void;
};

// ----------------------------------------------------------------------

// ALL CALCULATION AND DISPLAY IS HANDLED BASED ON STARTING PAGE = 1
export default function TablePagination({
  totalRows,
  currentPage,
  rowsPerPage = 10,
  rowsPerPageOptions = [5, 10, 25],
  changeRowsPerPageText,
  showInfo,
  customInfo,
  //
  onChangePage,
  onChangeRowsPerPage,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  // VAR
  const totalPageNumber: number = Math.ceil(totalRows / rowsPerPage);

  // HELPER FUNCTION
  function getPagesDisplayArr(): (number | null)[] {
    // Display array with possible duplicate page number
    let resultArr =
      currentPage === 1 || currentPage === totalPageNumber
        ? [1, 2, totalPageNumber - 1, totalPageNumber]
        : [
            1,
            2,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            totalPageNumber - 1,
            totalPageNumber,
          ];

    // Remove duplicated page number
    const uniqueSet = new Set<number>();
    resultArr = resultArr.filter((num) => !uniqueSet.has(num) && uniqueSet.add(num));

    // Display arr with null value inserted
    const result: (number | null)[] = [resultArr[0]];
    for (let i = 1; i < resultArr.length; i++) {
      resultArr[i] > resultArr[i - 1] + 1
        ? result.push(null, resultArr[i])
        : result.push(resultArr[i]);
    }
    return result;
  }

  function getTableInfo(): string {
    return `Showing ${(currentPage - 1) * rowsPerPage + 1} to ${Math.min(
      currentPage * rowsPerPage,
      totalRows
    )} of ${totalRows} results`;
  }

  function isPaginationBgDim(): boolean {
    const currentPageNumberOfRows =
      currentPage !== totalPageNumber || totalRows % rowsPerPage === 0
        ? rowsPerPage
        : totalRows % rowsPerPage;

    return currentPageNumberOfRows % 2 === 0;
  }

  return (
    <>
      {totalPageNumber <= 1 ? (
        onChangeRowsPerPage ? (
          <div
            className={`gv-pagination ${
              isPaginationBgDim() ? 'gv-surface-dim' : 'gv-surface-bright'
            }`}
          >
            <div />
            <div className="gv-amount-select">
              <span>{translate('wpone.general.table.viewRowsPerPage')}</span>
              <div className="gv-input gv-input-select">
                <select value={rowsPerPage} onChange={(e) => onChangeRowsPerPage(e)}>
                  {rowsPerPageOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <gv-icon src="/src/icons/expand_more.svg" />
              </div>
              <span>{translate(changeRowsPerPageText)}</span>
            </div>
          </div>
        ) : customInfo ? (
          <div
            className={`gv-pagination ${
              isPaginationBgDim() ? 'gv-surface-dim' : 'gv-surface-bright'
            }`}
          >
            <div style={{ marginRight: 'auto' }}>{customInfo}</div>
          </div>
        ) : (
          <></>
        )
      ) : (
        <div
          className={`gv-pagination ${
            isPaginationBgDim() ? 'gv-surface-dim' : 'gv-surface-bright'
          }`}
        >
          <nav>
            <ul>
              <li className={currentPage === 1 ? 'gv-disabled' : ''}>
                <button
                  type="button"
                  aria-label="Previous"
                  onClick={() => onChangePage(currentPage - 1)}
                >
                  <gv-icon src="/src/icons/keyboard_arrow_left.svg" />
                </button>
              </li>

              {getPagesDisplayArr().map((page, index) => (
                <li key={index} className={page === currentPage ? 'gv-active' : ''}>
                  {page === null ? (
                    <span>...</span>
                  ) : (
                    <button type="button" onClick={() => onChangePage(page)}>
                      {page}
                    </button>
                  )}
                </li>
              ))}

              <li className={currentPage === totalPageNumber ? 'gv-disabled' : ''}>
                <button
                  type="button"
                  aria-label="Next"
                  onClick={() => onChangePage(currentPage + 1)}
                >
                  <gv-icon src="/src/icons/keyboard_arrow_right.svg" />
                </button>
              </li>
            </ul>
          </nav>

          {changeRowsPerPageText && onChangeRowsPerPage ? (
            <div className="gv-amount-select">
              <span>{translate('wpone.general.table.viewRowsPerPage')}</span>
              <div className="gv-input gv-input-select">
                <select value={rowsPerPage} onChange={(e) => onChangeRowsPerPage(e)}>
                  {rowsPerPageOptions.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
                <gv-icon src="/src/icons/expand_more.svg" />
              </div>
              <span>{translate(changeRowsPerPageText)}</span>
            </div>
          ) : showInfo ? (
            <p>{getTableInfo()}</p>
          ) : customInfo ? (
            customInfo
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  );
}
