import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useParams } from 'react-router';
// redux
import { useGetZonePermissionsQuery } from 'src/redux/api/resourceApi';
// @types
import { AccessPermissionFullExpandedDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
import { AccessTypeEnum } from 'src/@types/user';
// hooks
import useGravityTable from 'src/hooks/useGravityTable';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// components
import { TableHeadCustom, TablePagination, TableSkeleton } from 'src/components/gravity/table';
import { Select } from 'src/components/gravity/form';
import { TextCustomize } from 'src/components/gravity/text';
//
import UserTableRow from './UserTableRow';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'wpone.domains.details.users.table.header.name', sort: true },
  { id: 'email', label: 'wpone.domains.details.users.table.header.email' },
  {
    id: 'role',
    label: 'wpone.domains.details.users.table.header.role',
    sort: true,
  },
  { id: 'action', label: '', align: 'right' },
];

// ----------------------------------------------------------------------

type Props = {
  ownerAccessPermissionInfo: AccessPermissionFullExpandedDTO | null;
  isGetZoneOwnerFetching: boolean;
  setIsGetZoneUsersFetching: Dispatch<SetStateAction<boolean>>;
  setAccessPermissionInfo: Dispatch<SetStateAction<AccessPermissionFullExpandedDTO | null>>;
  onOpenRevokeAccessDialog: VoidFunction;
  onOpenTransferOwnershipDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function UserTable({
  ownerAccessPermissionInfo,
  isGetZoneOwnerFetching,
  setIsGetZoneUsersFetching,
  setAccessPermissionInfo,
  onOpenRevokeAccessDialog,
  onOpenTransferOwnershipDialog,
}: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  const {
    order,
    orderBy,
    page,
    rowsPerPage,
    setPage,
    //
    onSort,
    onChangePage,
    onChangeRowsPerPage,
  } = useGravityTable({ defaultOrderBy: 'name', defaultRowsPerPage: 10 });

  // STATE
  const [filterType, setFilterType] = useState<AccessTypeEnum | 'all'>('all');

  // API
  const { data, isFetching } = useGetZonePermissionsQuery({
    name,
    rowsPerPage,
    page: page,
    accessType: filterType,
    sortType: order.toUpperCase(),
    sortField: orderBy === 'role' ? 'accessType' : orderBy,
  });

  // VAR
  const tableData: AccessPermissionFullExpandedDTO[] = data?.items || [];
  const isNotFound = !tableData.length && filterType !== 'all';
  const isNoData = !tableData.length && filterType === 'all';

  // EVENT FUNCTION
  function handleFilterType(type: AccessTypeEnum | 'all') {
    setFilterType(type);
    setPage(1);
  }

  //
  // Prevent no data after deleting user
  useEffect(() => {
    if (isNotFound && page !== 1) {
      setPage(1);
    }
  }, [data, isNotFound, page, setPage]);

  useEffect(() => {
    setIsGetZoneUsersFetching(isFetching);
  }, [isFetching, setIsGetZoneUsersFetching]);

  return (
    <div className="gv-flex-column-md">
      {(isFetching || isGetZoneOwnerFetching || !isNoData) && (
        <div className="gv-content-container gv-p-sm">
          <div style={{ maxWidth: isMobile ? '100%' : 300 }}>
            <Select
              condensed
              value={filterType}
              onChange={(e) => handleFilterType(e.target.value as AccessTypeEnum | 'all')}
              options={['all', ...Object.values(AccessTypeEnum).slice(0, -1)].map((option) => ({
                value: option,
                label: translate(`wpone.domains.details.users.table.userRole.${option}`),
              }))}
            />
          </div>
        </div>
      )}

      <div className="gv-data-table">
        <div className="data-table-scroll-handler" style={{ overflowX: 'auto' }}>
          <table className="gv-col-4-shrink">
            <TableHeadCustom
              headers={
                isNoData || isNotFound || isFetching || isGetZoneOwnerFetching
                  ? TABLE_HEAD.map((header) => ({ ...header, sort: false }))
                  : TABLE_HEAD
              }
              order={order}
              orderBy={orderBy}
              onSort={onSort}
            />
            <tbody>
              {!isFetching && !isGetZoneOwnerFetching
                ? tableData.map((row) => (
                    <UserTableRow
                      key={row.id}
                      row={row}
                      ownerAccessPermissionInfo={ownerAccessPermissionInfo}
                      setAccessPermissionInfo={setAccessPermissionInfo}
                      onOpenRevokeAccessDialog={onOpenRevokeAccessDialog}
                      onOpenTransferOwnershipDialog={onOpenTransferOwnershipDialog}
                    />
                  ))
                : [...Array(rowsPerPage)].map((_, index) => (
                    <TableSkeleton key={index} headers={TABLE_HEAD} />
                  ))}
            </tbody>
          </table>
        </div>

        {!isFetching && !isGetZoneOwnerFetching && (isNotFound || isNoData) && (
          <div className="gv-empty-state">
            <div className="gv-empty-state-content">
              <p className="gv-text-lg gv-text-bold">
                {isNoData
                  ? translate('wponesupport.domains.details.users.table.noData.title')
                  : translate('wpone.domains.details.users.table.notFound.title')}
              </p>
              <TextCustomize
                text={
                  isNoData
                    ? translate('wponesupport.domains.details.users.table.noData.description')
                    : translate('wpone.domains.details.users.table.notFound.description', {
                        role: translate(`wpone.domains.details.users.table.userRole.${filterType}`),
                      })
                }
              />
            </div>
          </div>
        )}

        {!isFetching && !isGetZoneOwnerFetching && !isNotFound && !isNoData && (
          <TablePagination
            totalRows={data?.counts || 0}
            currentPage={page}
            onChangePage={onChangePage}
            rowsPerPage={rowsPerPage}
            changeRowsPerPageText="wpone.domains.details.users.table.pagination.viewRowsPerPage"
            onChangeRowsPerPage={onChangeRowsPerPage}
          />
        )}
      </div>
    </div>
  );
}
