import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import {
  LighthousePerformanceSubScoreEnum,
  LighthouseScoreEnum,
  LighthouseStatusEnum,
} from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import {
  convertLighthouseStatsDisplay,
  convertLighthouseValueToStatus,
  convertLighthouseStatusToColorCode,
} from 'src/utils/convert';
// components
import { PageSection } from 'src/components/gravity/page';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
};

// ----------------------------------------------------------------------

export default function LighthousePerformanceBlock({ site }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // VAR
  const lighthouse_statistics = site?.lighthouse_statistics;

  // HELPER FUNCTION
  function getStats(lighthouseStat: LighthouseScoreEnum | LighthousePerformanceSubScoreEnum) {
    switch (lighthouseStat) {
      case LighthouseScoreEnum.PERFORMANCE:
        return {
          previousValue: lighthouse_statistics?.previous_performance.score,
          currentValue: lighthouse_statistics?.current_performance.score,
        };
      case LighthouseScoreEnum.ACCESSIBILITY:
        return {
          previousValue: lighthouse_statistics?.previous_accessibility,
          currentValue: lighthouse_statistics?.current_accessibility,
        };
      case LighthouseScoreEnum.SEO:
        return {
          previousValue: lighthouse_statistics?.previous_seo,
          currentValue: lighthouse_statistics?.current_seo,
        };
      case LighthouseScoreEnum.BEST_PRACTICES:
        return {
          previousValue: lighthouse_statistics?.previous_best_practices,
          currentValue: lighthouse_statistics?.current_best_practices,
        };
      case LighthousePerformanceSubScoreEnum.FCP:
        return {
          previousValue: lighthouse_statistics?.previous_performance.first_contentful_paint,
          currentValue: lighthouse_statistics?.current_performance.first_contentful_paint,
        };
      case LighthousePerformanceSubScoreEnum.SPEED_INDEX:
        return {
          previousValue: lighthouse_statistics?.previous_performance.speed_index,
          currentValue: lighthouse_statistics?.current_performance.speed_index,
        };
      case LighthousePerformanceSubScoreEnum.LCP:
        return {
          previousValue: lighthouse_statistics?.previous_performance.largest_contentful_paint,
          currentValue: lighthouse_statistics?.current_performance.largest_contentful_paint,
        };
      case LighthousePerformanceSubScoreEnum.BLOCKING:
        return {
          previousValue: lighthouse_statistics?.previous_performance.total_blocking_time,
          currentValue: lighthouse_statistics?.current_performance.total_blocking_time,
        };
      case LighthousePerformanceSubScoreEnum.CLS:
        return {
          previousValue: lighthouse_statistics?.previous_performance.cumulative_layout_shift,
          currentValue: lighthouse_statistics?.current_performance.cumulative_layout_shift,
        };
      default:
        return {
          previousValue: undefined,
          currentValue: undefined,
        };
    }
  }

  return (
    <PageSection
      title={translate('wpone.sites.details.statistics.lighthousePerformance.title')}
      description={translate('wpone.sites.details.statistics.lighthousePerformance.description')}
      action={{
        text: translate('wpone.sites.details.statistics.lighthousePerformance.action'),
        onClick: () => {
          ReactGA.event({
            category: 'button',
            action: 'click',
            label: 'learn-more-about-web-vitals',
          });
          window.open(`https://pagespeed.web.dev/analysis?url=https%3A%2F%2F${name}`, '_blank');
        },
        isLink: true,
        iconName: 'open_in_new',
      }}
    >
      <div className="gv-flex-column-md">
        <div className="gv-flex-column-sm gv-tab-flex-row gv-tab-gap-md">
          {[
            LighthouseStatusEnum.unknown,
            LighthouseStatusEnum.alert,
            LighthouseStatusEnum.warning,
            LighthouseStatusEnum.good,
          ]
            .map((statusValue) => ({
              status: statusValue,
              ...convertLighthouseStatusToColorCode(statusValue),
            }))
            .map((statusInfo) => (
              <div key={statusInfo.status} className="gv-flex gv-flex-row" style={{ gap: '4px' }}>
                <span style={{ color: `var(${statusInfo.color})` }}>
                  <gv-icon src={`/src/icons/${statusInfo.iconName}.svg`} />
                </span>
                <p className="gv-text-sm">
                  {translate(
                    `wpone.sites.details.statistics.lighthousePerformance.colorCodes.${statusInfo.status}`
                  )}
                </p>
              </div>
            ))}
        </div>

        <div className={`gv-grid gv-grid-cols-${isMobile ? 1 : 2} gv-desk-grid-cols-3 gv-gap-lg`}>
          {[
            LighthouseScoreEnum.PERFORMANCE,
            LighthouseScoreEnum.ACCESSIBILITY,
            LighthouseScoreEnum.SEO,
            LighthouseScoreEnum.BEST_PRACTICES,
            LighthousePerformanceSubScoreEnum.FCP,
            LighthousePerformanceSubScoreEnum.SPEED_INDEX,
            LighthousePerformanceSubScoreEnum.LCP,
            LighthousePerformanceSubScoreEnum.BLOCKING,
            LighthousePerformanceSubScoreEnum.CLS,
          ]
            .map((lighthouseStat) => ({
              lighthouseStat: lighthouseStat,
              ...getStats(lighthouseStat),
            }))
            .map((stat) => (
              <LighthousePerformanceStatsCard
                key={stat.lighthouseStat}
                lighthouseStat={stat.lighthouseStat}
                previousValue={stat.previousValue}
                currentValue={stat.currentValue}
              />
            ))}
        </div>
      </div>
    </PageSection>
  );
}

// ----------------------------------------------------------------------

type LighthousePerformanceStatsCardProps = {
  lighthouseStat: LighthouseScoreEnum | LighthousePerformanceSubScoreEnum;
  previousValue: number | undefined;
  currentValue: number | undefined;
};

// ----------------------------------------------------------------------

function LighthousePerformanceStatsCard({
  lighthouseStat,
  previousValue,
  currentValue,
}: LighthousePerformanceStatsCardProps) {
  // HOOK
  const { translate } = useLocales();

  // VAR
  const lighthouseDisplayInfo = convertLighthouseStatsDisplay(
    lighthouseStat,
    previousValue,
    currentValue
  );
  const lightHouseStatus = convertLighthouseValueToStatus(lighthouseStat, currentValue);
  const lighthouseColorCode = convertLighthouseStatusToColorCode(lightHouseStatus);

  return (
    <div className="gv-content-container gv-p-lg gv-flex-column-sm">
      <div className="gv-flex gv-flex-row gv-justify-between">
        <p className="gv-text-sm gv-text-bold">
          {translate(
            `wpone.sites.details.statistics.lighthousePerformance.infoCard.title.${lighthouseStat}`
          )}
        </p>
        <span style={{ color: `var(${lighthouseColorCode.color})` }}>
          <gv-icon src={`/src/icons/${lighthouseColorCode.iconName}.svg`} />
        </span>
      </div>

      {lighthouseDisplayInfo.convertedCurrentValueDisplay !== undefined ? (
        <div className="gv-flex gv-flex-row" style={{ gap: '4px' }}>
          <p className="gv-text-sm gv-text-bold">
            {lighthouseDisplayInfo.convertedCurrentValueDisplay}
          </p>
          {lighthouseDisplayInfo.valueDiffDisplay && (
            <p className="gv-text-sm gv-text-on-alternative">
              {lighthouseDisplayInfo.valueDiffDisplay}
            </p>
          )}
        </div>
      ) : (
        <p className="gv-text-sm gv-text-on-alternative">
          {translate('wpone.sites.details.statistics.lighthousePerformance.infoCard.noData')}
        </p>
      )}

      <p className="gv-text-sm gv-text-on-alternative">
        {translate(
          `wpone.sites.details.statistics.lighthousePerformance.infoCard.description.${lighthouseStat}`
        )}
      </p>
    </div>
  );
}
