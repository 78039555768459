// redux
import { useSelector } from 'src/redux/store';
// @types
import { ListSiteResponseDTO } from '@wp-one/sites-microservice-types';
import { SiteStatusEnum } from 'src/@types/site';
import { AccessTypeEnum, UserRoleEnum } from 'src/@types/user';
// hooks
import useHighestUserRole from 'src/hooks/useHighestUserRole';
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// components
import Badge from 'src/components/gravity/Badge';

// ----------------------------------------------------------------------

type Props = {
  site: ListSiteResponseDTO;
  onOpenUpdatePHPVersionDialog: VoidFunction;
  onOpenResetSiteDialog: VoidFunction;
  onOpenDeleteSiteDialog: VoidFunction;
  onOpenReactivateSiteDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function AdministrationBlock({
  site,
  onOpenUpdatePHPVersionDialog,
  onOpenResetSiteDialog,
  onOpenDeleteSiteDialog,
  onOpenReactivateSiteDialog,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  const { highestUserRole, isHighestUserRoleLoading } = useHighestUserRole();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // VAR
  const administrationOptionDisplayInfo = displayAdministrationOptionInfo();

  // HELPER FUNCTION
  function displayAdministrationOptionInfo() {
    let optionArr: {
      id: string;
      iconName: string;
      title: string;
      description: string;
      currentPhpVersion?: number;
      action: VoidFunction;
    }[] = [
      {
        id: 'update_php',
        iconName: 'php',
        title: 'wpone.sites.details.settings.siteAdministration.changePhp.title',
        description: 'wpone.sites.details.settings.siteAdministration.changePhp.description',
        currentPhpVersion: site.php_version,
        action: onOpenUpdatePHPVersionDialog,
      },
    ];

    if (user && !isHighestUserRoleLoading) {
      const isNonCustomer =
        user.role === UserRoleEnum.support || user.role === UserRoleEnum.serviceAccount;
      const isCustomerDeveloper =
        user.role === UserRoleEnum.customer && highestUserRole !== AccessTypeEnum.developer;
      const isCustomerOwner =
        user.role === UserRoleEnum.customer && highestUserRole === AccessTypeEnum.owner;

      // Support, SA, and Customer as non-Developer can RESET an active site
      if ((isNonCustomer || !isCustomerDeveloper) && site.state === SiteStatusEnum.active) {
        optionArr.push({
          id: 'reset_site',
          iconName: 'replay',
          title: 'wpone.sites.details.settings.siteAdministration.resetSite.title',
          description: 'wpone.sites.details.settings.siteAdministration.resetSite.description',
          action: onOpenResetSiteDialog,
        });
      }

      // Support and SA can DELETE site with any state
      // Customer as Owner can DELETE an active site
      if (isNonCustomer || (isCustomerOwner && site.state === SiteStatusEnum.active)) {
        optionArr.push({
          id: 'delete_site',
          iconName: 'delete',
          title: 'wpone.sites.details.settings.siteAdministration.deleteSite.title',
          description: 'wpone.sites.details.settings.siteAdministration.deleteSite.description',
          action: onOpenDeleteSiteDialog,
        });
      }

      // Support and SA can REACTIVATE a suspended site
      if (isNonCustomer && site.state === SiteStatusEnum.suspended) {
        optionArr.push({
          id: 'restart_site',
          iconName: 'restart_alt',
          title: 'wponesupport.sites.details.settings.siteAdministration.reactivateSite.title',
          description:
            'wponesupport.sites.details.settings.siteAdministration.reactivateSite.description',
          action: onOpenReactivateSiteDialog,
        });
      }
    }

    return optionArr;
  }

  return (
    <div className="gv-flex-column-md">
      <div className="gv-flex gv-flex-col" style={{ gap: '4px' }}>
        <p className="gv-text-lg gv-text-bold">
          {translate('wpone.sites.details.settings.siteAdministration.title')}
        </p>
        <p className="gv-text-sm">
          {translate('wpone.sites.details.settings.siteAdministration.description')}
        </p>
      </div>

      <div className="gv-grid gv-gap-lg gv-grid-cols-2">
        {/* Administration option block display logic: */}
        {/* Screen width larger than Desktop: Never expand, display two per row */}
        {/* Screen width smaller than Desktop: Always expand, display one per row */}
        {/* Screen width in Desktop range: Display two per row, if one row only has one then expand it */}
        {administrationOptionDisplayInfo.map((option, index) => (
          <AdministrationOption
            key={option.id}
            iconName={option.iconName}
            title={option.title}
            description={option.description}
            expandOnDesktop={
              (index + 1) % 2 !== 0 && index + 1 === administrationOptionDisplayInfo.length
            }
            currentPhpVersion={option.currentPhpVersion}
            onClick={option.action}
          />
        ))}
      </div>
    </div>
  );
}

// ----------------------------------------------------------------------

type AdministrationOptionProps = {
  iconName: string;
  title: string;
  description: string;
  expandOnDesktop: boolean;
  currentPhpVersion?: number;
  onClick: VoidFunction;
};

// ----------------------------------------------------------------------

function AdministrationOption({
  iconName,
  title,
  description,
  expandOnDesktop,
  currentPhpVersion,
  onClick,
}: AdministrationOptionProps) {
  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  const isUpDesktop = useResponsive('up', 'desktop_max');

  const isDownDesktop = useResponsive('down', 'desktop_min');

  return (
    <div
      className={`gv-content-container gv-p-lg gv-flex-row-md gv-items-center gv-span-${
        isUpDesktop ? 1 : isDownDesktop ? 2 : expandOnDesktop ? 2 : 1
      }`}
      onClick={onClick}
      style={{
        cursor: 'pointer',
      }}
    >
      <gv-icon src={`/src/icons/${iconName}.svg`} />
      <div className="gv-flex-1">
        <div
          className={`gv-flex gv-flex-col gv-tab-flex-row gv-items-start gv-tab-items-center`}
          style={{
            gap: isMobile ? 0 : '8px',
          }}
        >
          <p className="gv-text-lg gv-text-bold">{translate(title)}</p>
          {currentPhpVersion && (
            <Badge
              type="generic"
              text={`PHP ${currentPhpVersion.toFixed(1)}`}
              style={{ minWidth: '64px' }}
            />
          )}
        </div>
        <p className="gv-caption-lg gv-text-on-alternative">{translate(description)}</p>
      </div>
      <gv-icon src="/src/icons/arrow_forward.svg" />
    </div>
  );
}
