// @types
import { TableHeader } from 'src/@types/layout';

// ----------------------------------------------------------------------

type Props = {
  checkbox?: boolean;
  headers: TableHeader[];
};

// ----------------------------------------------------------------------

export default function TableSkeleton({ checkbox, headers }: Props) {
  return (
    <tr>
      {(checkbox ? [{ id: 'checkbox' }, ...headers] : headers).map((_, index) => (
        <td key={index}>
          <div className="gv-skeleton" />
        </td>
      ))}
    </tr>
  );
}
