import { useNavigate, useParams } from 'react-router';
// @types
import {
  PerformanceScoreGeneralInfo,
  LighthouseScoreEnum,
  LighthouseStatusEnum,
} from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { convertLighthouseValueToStatus } from 'src/utils/convert';
// components
import { PageSection } from 'src/components/gravity/page';
//
import PerformanceCard from './PerformanceCard';

// ----------------------------------------------------------------------

const PERFORMANCE_CARDS_INFO: {
  performance: PerformanceScoreGeneralInfo;
  seo: PerformanceScoreGeneralInfo;
  accessibility: PerformanceScoreGeneralInfo;
  bestPractices: PerformanceScoreGeneralInfo;
} = {
  performance: {
    type: LighthouseScoreEnum.PERFORMANCE,
    description: {
      unknown:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.performance.description.unknown',
      good: 'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.performance.description.good',
      warning:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.performance.description.warning',
      alert:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.performance.description.alert',
    },
  },
  seo: {
    type: LighthouseScoreEnum.SEO,
    description: {
      unknown:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.seo.description.unknown',
      good: 'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.seo.description.good',
      warning:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.seo.description.warning',
      alert:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.seo.description.alert',
    },
  },
  accessibility: {
    type: LighthouseScoreEnum.ACCESSIBILITY,
    description: {
      unknown:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.accessibility.description.unknown',
      good: 'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.accessibility.description.good',
      warning:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.accessibility.description.warning',
      alert:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.accessibility.description.alert',
    },
  },
  bestPractices: {
    type: LighthouseScoreEnum.BEST_PRACTICES,
    description: {
      unknown:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.bestPractices.description.unknown',
      good: 'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.bestPractices.description.good',
      warning:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.bestPractices.description.warning',
      alert:
        'dashboard.sites.details.mwp.overview.performanceMonitor.cardInfo.bestPractices.description.alert',
    },
  },
};

// ----------------------------------------------------------------------

export default function PerformanceMonitorBlock() {
  const { name, namespace, cluster } = useParams();

  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // VAR
  const scores: {
    performance: number | undefined;
    seo: number | undefined;
    accessibility: number | undefined;
    bestPractices: number | undefined;
  } = {
    performance: 0.1,
    seo: 0.2,
    accessibility: 0.3,
    bestPractices: 0.4,
  };

  const colorCodes: {
    performance: LighthouseStatusEnum;
    seo: LighthouseStatusEnum;
    accessibility: LighthouseStatusEnum;
    bestPractices: LighthouseStatusEnum;
  } = {
    performance: convertLighthouseValueToStatus(
      LighthouseScoreEnum.PERFORMANCE,
      scores.performance
    ),
    seo: convertLighthouseValueToStatus(LighthouseScoreEnum.SEO, scores.seo),
    accessibility: convertLighthouseValueToStatus(
      LighthouseScoreEnum.ACCESSIBILITY,
      scores.accessibility
    ),
    bestPractices: convertLighthouseValueToStatus(
      LighthouseScoreEnum.PERFORMANCE,
      scores.bestPractices
    ),
  };

  // HELPER FUNCTION
  function getPerformanceDescription() {
    const counts = {
      unknown: 0,
      good: 0,
      warning: 0,
      alert: 0,
    };

    Object.values(colorCodes).forEach((code) => {
      counts[code]++;
    });

    switch (true) {
      case counts.unknown > 0:
        return 'dashboard.sites.details.mwp.overview.performanceMonitor.description.unknown';
      case counts.alert > 0:
        return 'dashboard.sites.details.mwp.overview.performanceMonitor.description.alert';
      case counts.warning > 0:
        return 'dashboard.sites.details.mwp.overview.performanceMonitor.description.warning';
      default:
        return 'dashboard.sites.details.mwp.overview.performanceMonitor.description.good';
    }
  }

  return (
    <PageSection
      title={translate('dashboard.sites.details.mwp.overview.performanceMonitor.title')}
      description={translate(getPerformanceDescription())}
      action={{
        text: translate('dashboard.sites.details.mwp.overview.performanceMonitor.action'),
        onClick: () => navigate(`/sites/performance/${cluster}/${namespace}/${name}`),
      }}
    >
      <div
        className={`gv-grid gv-grid-cols-${
          isMobile ? 1 : 2
        } gv-desk-grid-cols-4 gv-gap-lg gv-w-full`}
      >
        {[
          LighthouseScoreEnum.PERFORMANCE,
          LighthouseScoreEnum.SEO,
          LighthouseScoreEnum.ACCESSIBILITY,
          LighthouseScoreEnum.BEST_PRACTICES,
        ].map((type) => (
          <PerformanceCard
            key={type}
            score={scores[type]}
            colorCode={colorCodes[type]}
            cardInfo={PERFORMANCE_CARDS_INFO[type]}
          />
        ))}
      </div>
    </PageSection>
  );
}
