// @types
import { AccessPermissionFullExpandedDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import RevokeAccessForm from './RevokeAccessForm';

// ----------------------------------------------------------------------

type Props = {
  accessPermissionInfo: AccessPermissionFullExpandedDTO | null;
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function RevokeAccessDialog({ accessPermissionInfo, open, onClose }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <RevokeAccessForm accessPermissionInfo={accessPermissionInfo} onClose={onClose} />
      }
    />
  );
}
