// hooks
import useLocales from 'src/hooks/useLocales';
// components
import { PageSection } from 'src/components/gravity/page';
import { TextCustomize } from 'src/components/gravity/text';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

type Props = {
  onOpenDeleteDomainDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteDomainBlock({ onOpenDeleteDomainDialog }: Props) {
  // HOOK
  const { translate } = useLocales();

  return (
    <PageSection title={translate('wpone.domains.details.settings.deleteDomain.title')}>
      <div className="gv-content-container gv-w-full gv-flex-column-md gv-p-lg">
        <TextCustomize
          text={translate('wpone.domains.details.settings.deleteDomain.description')}
        />

        <ButtonGroup>
          <Button
            uiType="destructive"
            text={translate('wpone.general.action.delete')}
            onClick={onOpenDeleteDomainDialog}
          />
        </ButtonGroup>
      </div>
    </PageSection>
  );
}
