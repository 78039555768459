import { CSSProperties, HTMLAttributes, ReactNode } from 'react';

// ----------------------------------------------------------------------

type IProps = {
  size?: 'small' | 'medium';
  children: ReactNode;
  style?: CSSProperties;
};

type Props = IProps & HTMLAttributes<HTMLDivElement>;

// ----------------------------------------------------------------------

export default function IconWrapper({ size = 'medium', children, style, ...other }: Props) {
  return (
    <div
      style={{
        height: `${size === 'small' ? 20 : 24}px`,
        ...style,
      }}
      {...other}
    >
      {children}
    </div>
  );
}
