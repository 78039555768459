import { useParams } from 'react-router';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// redux
import { useImportOwnerMutation } from 'src/redux/api/resourceApi';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @types
import { ResourceTypeEnum } from 'src/@types/user';
// hooks
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFTextField } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import AlertBanner from 'src/components/AlertBanner';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  afterSubmit?: string;
};

type Props = {
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function ImportOwnerForm({ onClose }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [importOwner, { isLoading }] = useImportOwnerMutation();

  // FORM
  const defaultValues = {
    email: '',
  };

  const AddUserPermissionSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(translate('wpone.general.inputField.emailAddress.validation1'))
      .required(translate('wpone.general.inputField.emailAddress.validation2')),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(AddUserPermissionSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'import-owner',
    });

    await importOwner({
      externalId: name as string,
      resourceType: ResourceTypeEnum.zone,
      userEmail: data.email.trim(),
    })
      .unwrap()
      .then(() => {
        displayToast(translate('wpone.general.requestResponse.access.successMessage.importOwner'));
        onClose();
      })
      .catch((error) => {
        // Error message check order: is customer => access exists => general
        const errCode = (error as { status: number; data: any }).status;

        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(convertStatusCode(errCode)),
          });
        } else {
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        }
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.domains.details.users.importOwnerDialog.title')}
        </h2>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-lg" style={{ overflowX: 'auto' }}>
            {!!errors.afterSubmit && (
              <AlertBanner severity="error">{errors.afterSubmit.message}</AlertBanner>
            )}
            <RHFTextField
              name="email"
              label={translate('wpone.general.inputField.emailAddress.label')}
              labelId="owner-email"
              placeholder="example@gmail.com"
            />
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.domains.details.users.importOwnerDialog.action')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
