import { useParams } from 'react-router';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// redux
import { useAddZoneUserMutation } from 'src/redux/api/resourceApi';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// hooks
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { fTranslationDynamicKey } from 'src/utils/format';
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFTextField } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import AlertBanner from 'src/components/AlertBanner';

// ----------------------------------------------------------------------

type FormValuesProps = {
  email: string;
  afterSubmit?: string;
};

type Props = {
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function AddUserForm({ onClose }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [addZoneUser, { isLoading }] = useAddZoneUserMutation();

  // FORM
  const defaultValues = {
    email: '',
  };

  const AddUserPermissionSchema = Yup.object().shape({
    email: Yup.string()
      .trim()
      .email(translate('wpone.domains.details.users.addUserDialog.inputField.validation1'))
      .required(translate('wpone.domains.details.users.addUserDialog.inputField.validation2')),
  });

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(AddUserPermissionSchema),
    defaultValues,
  });

  const {
    watch,
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  let emailInputValue = watch('email');

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'add-dns-user',
    });

    await addZoneUser({
      zoneName: name as string,
      userForSharing: data.email.trim(),
    })
      .unwrap()
      .then(() => {
        displayToast(
          translate('wpone.domains.details.users.addUserDialog.requestResponse.successMessage')
        );
        onClose();
      })
      .catch((error) => {
        // Error message check order: is customer => access exists => general
        const errCode = (error as { status: number; data: any }).status;
        const errMsg = (error as { status: number; data: any }).data.message;
        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(
              [
                `wpone.domains.details.users.addUserDialog.requestResponse.errorMessage.${fTranslationDynamicKey(
                  errMsg
                )}`,
                convertStatusCode(errCode),
              ],
              { user: emailInputValue, domain: name }
            ),
          });
        } else {
          displayToast(
            translate(
              [
                `wpone.domains.details.users.addUserDialog.requestResponse.errorMessage.${fTranslationDynamicKey(
                  errMsg
                )}`,
                convertStatusCode(errCode),
              ],
              { user: emailInputValue, domain: name }
            ),
            { variant: 'alert' }
          );
        }
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.domains.details.users.addUserDialog.title')}
        </h2>

        <FormProvider methods={methods}>
          <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
            <p>{translate('wpone.domains.details.users.addUserDialog.description')}</p>

            {!!errors.afterSubmit && (
              <AlertBanner severity="error">{errors.afterSubmit.message}</AlertBanner>
            )}
            <RHFTextField
              name="email"
              label={translate('wpone.domains.details.users.addUserDialog.inputField.label')}
              labelId="user-email"
            />
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.domains.details.users.addUserDialog.action')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
