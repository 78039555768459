import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// redux
import { useSelector, useDispatch } from 'src/redux/store';
import { useAddDnsRecordMutation } from 'src/redux/api/domainApi';
import { addRecordAddedDomain } from 'src/redux/features/domain';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @types
import { TTL, FormattedCreateDNSRequestBodyDTO, DnsRecordType } from 'src/@types/domain';
import { BrandIdEnum, UserRoleEnum } from 'src/@types/user';
import { UserGetResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
// hooks
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import useLocales from 'src/hooks/useLocales';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFTextField, RHFSelect } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';

// ----------------------------------------------------------------------

enum MXRecordPresetEnum {
  GOOGLE_WORKSPACE = 'Google Workspace',
  ZONER_MAIL = 'Zoner Mail',
  ZONER_WEBHOTEL = 'Zoner Webhotel Mail',
  HOSTNET_MAIL = 'Hostnet Mail',
  MICROSOFT_365 = 'Microsoft 365',
  CUSTOM = 'Custom',
}

type FormValuesProps = {
  preset: MXRecordPresetEnum;
  microsoftCustomerId?: string;
  microsoftMXContent?: string;
  hostnetDkimKey?: string;
  hostname: string;
  pointsTo: string;
  priority: number;
  ttl: TTL;
  afterSubmit?: string;
};

type Props = {
  isBunnyDns: boolean;
  setFormError: Dispatch<SetStateAction<string | undefined>>;
};

// ----------------------------------------------------------------------

export default function AddRecordMXForm({ isBunnyDns, setFormError }: Props) {
  const { name } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [addDnsRecord] = useAddDnsRecordMutation();

  // STATE
  const { user } = useSelector((state) => state.auth);

  const [recordArr, setRecordArr] = useState<FormattedCreateDNSRequestBodyDTO[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  // FORM
  const FormSchema = Yup.object().shape({
    microsoftCustomerId: Yup.string().when('preset', {
      is: MXRecordPresetEnum.MICROSOFT_365,
      then: Yup.string()
        .trim()
        .required(
          translate('wpone.domains.details.dnsRecords.form.microsoftCustomerId.validation')
        ),
    }),
    microsoftMXContent: Yup.string().when('preset', {
      is: MXRecordPresetEnum.MICROSOFT_365,
      then: Yup.string()
        .trim()
        .required(translate('wpone.domains.details.dnsRecords.form.microsoftMXContent.validation')),
    }),
    hostnetDkimKey: Yup.string().when('preset', {
      is: MXRecordPresetEnum.HOSTNET_MAIL,
      then: Yup.string()
        .trim()
        .required(translate('wpone.domains.details.dnsRecords.form.hostnetDkimKey.validation')),
    }),
    pointsTo: Yup.string().when('preset', {
      is: MXRecordPresetEnum.CUSTOM,
      then: Yup.string()
        .trim()
        .required(translate('wpone.domains.details.dnsRecords.form.pointsTo.validation')),
    }),
    priority: Yup.number()
      .transform((val, orig) => (orig === '' ? undefined : val))
      .required(translate('wpone.domains.details.dnsRecords.form.priority.validation1'))
      .typeError(translate('wpone.domains.details.dnsRecords.form.priority.validation2')),
  });

  const defaultValues = {
    preset: MXRecordPresetEnum.GOOGLE_WORKSPACE,
    microsoftCustomerId: '',
    microsoftMXContent: '',
    hostnetDkimKey: '',
    hostname: '',
    pointsTo: '',
    priority: 1,
    ttl: TTL.auto,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const { watch, handleSubmit } = methods;

  const presetValue = watch('preset');
  const microsoftCustomerIdValue = watch('microsoftCustomerId');
  const microsoftMXContentValue = watch('microsoftMXContent');
  const hostnetDkimKeyValue = watch('hostnetDkimKey');

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (!name) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: `add-dns-record`,
    });

    if (data.preset !== MXRecordPresetEnum.CUSTOM) {
      let successfullyAddedRecords = [];

      // Adding records
      setIsLoading(true);
      for (const record of recordArr) {
        let newRecord = {
          type: record.type,
          name: record.name,
          content: record.content,
          ttl: record.ttl,
        } as FormattedCreateDNSRequestBodyDTO;

        if (record.priority !== undefined) {
          newRecord = {
            ...newRecord,
            priority: record.priority,
          } as FormattedCreateDNSRequestBodyDTO;
        }

        if (record.type === DnsRecordType.SRV) {
          let weight = record.content.split(' ')[0];
          let port = record.content.split(' ')[1];
          let target = record.content.split(' ')[2];

          newRecord = {
            ...newRecord,
            content: `${weight}\t${port}\t${target}`,
          };
        }

        if (isMountedRef.current) {
          await addDnsRecord({
            domainName: name,
            newDnsRecord: newRecord,
            isBunnyDns:
              record.type === DnsRecordType.SRV || presetValue === MXRecordPresetEnum.ZONER_MAIL,
          })
            .unwrap()
            .then((fulfilled) => {
              if (fulfilled) {
                successfullyAddedRecords.push(fulfilled);
              }
            })
            .catch(() => {});
        }
      }

      // Handle result
      if (successfullyAddedRecords.length === recordArr.length) {
        if (isMountedRef.current) {
          navigate(`/dns/records/${name}`);
          dispatch(addRecordAddedDomain(name));
        } else {
          displayToast(
            translate('wpone.domains.details.dnsRecords.addRecord.requestResponse.successMessage')
          );
        }
      } else {
        if (isMountedRef.current) {
          setIsLoading(false);
          setFormError(
            translate(
              'wpone.domains.details.dnsRecords.addRecord.requestResponse.errorMessage.multipleRecords',
              { addedRecords: successfullyAddedRecords.length, total: recordArr.length }
            )
          );
        } else {
          displayToast(
            translate(
              'wpone.domains.details.dnsRecords.addRecord.requestResponse.errorMessage.multipleRecords',
              { addedRecords: successfullyAddedRecords.length, total: recordArr.length }
            ),
            { variant: 'alert' }
          );
        }
      }
    } else {
      const newDnsRecord = {
        type: DnsRecordType.MX,
        name: data.hostname.trim() ? `${data.hostname.trim()}.${name}` : `${name}`,
        content: data.pointsTo.trim(),
        priority: data.priority,
        ttl: data.ttl,
      } as FormattedCreateDNSRequestBodyDTO;

      setIsLoading(true);

      await addDnsRecord({
        domainName: name,
        newDnsRecord,
        isBunnyDns: isBunnyDns,
      })
        .unwrap()
        .then((data) => {
          if (data) {
            if (isMountedRef.current) {
              navigate(`/dns/records/${name}`);
              dispatch(addRecordAddedDomain(name));
            } else {
              displayToast(
                translate(
                  'wpone.domains.details.dnsRecords.addRecord.requestResponse.successMessage'
                )
              );
            }
          } else {
            throw new Error();
          }
        })
        .catch(() => {
          if (isMountedRef.current) {
            setFormError(
              translate(
                'wpone.domains.details.dnsRecords.addRecord.requestResponse.errorMessage.singleRecord'
              )
            );
          } else {
            displayToast(
              translate(
                'wpone.domains.details.dnsRecords.addRecord.requestResponse.errorMessage.singleRecord'
              ),
              { variant: 'alert' }
            );
          }
        });
    }
  };

  // HELPER FUNCTION
  function getPresetArr(user: UserGetResponse | null): MXRecordPresetEnum[] {
    // Support/Service account can see all presets
    // Only customer from Zoner brand can see Zoner preset, same goes for Hostnet

    const result = Object.values(MXRecordPresetEnum);

    if (!user || !user.brand) return [];

    if (user.role !== UserRoleEnum.customer) return result;

    const isZonerBrand = user.brand.id === BrandIdEnum.zoner;
    const isHostnetBrand = user.brand.id === BrandIdEnum.hostnet;

    return result.filter((value) => {
      if (isZonerBrand) return value !== MXRecordPresetEnum.HOSTNET_MAIL;
      if (isHostnetBrand)
        return (
          value !== MXRecordPresetEnum.ZONER_MAIL && value !== MXRecordPresetEnum.ZONER_WEBHOTEL
        );
      return (
        value !== MXRecordPresetEnum.HOSTNET_MAIL &&
        value !== MXRecordPresetEnum.ZONER_MAIL &&
        value !== MXRecordPresetEnum.ZONER_WEBHOTEL
      );
    });
  }

  function displayPresetForm() {
    switch (presetValue) {
      case MXRecordPresetEnum.GOOGLE_WORKSPACE:
      case MXRecordPresetEnum.ZONER_MAIL:
      case MXRecordPresetEnum.ZONER_WEBHOTEL:
      case MXRecordPresetEnum.HOSTNET_MAIL:
      case MXRecordPresetEnum.MICROSOFT_365:
        return (
          <>
            {presetValue === MXRecordPresetEnum.HOSTNET_MAIL && (
              <RHFTextField
                name="hostnetDkimKey"
                labelId="hostnetDkimKey"
                label={translate('wpone.domains.details.dnsRecords.form.hostnetDkimKey.label')}
                addonPrefix="v=DKIM1; p="
              />
            )}

            {presetValue === MXRecordPresetEnum.MICROSOFT_365 && (
              <>
                <RHFTextField
                  name="microsoftCustomerId"
                  labelId="microsoftCustomerId"
                  label={translate(
                    'wpone.domains.details.dnsRecords.form.microsoftCustomerId.label'
                  )}
                />
                <RHFTextField
                  name="microsoftMXContent"
                  labelId="microsoftMXContent"
                  label={translate(
                    'wpone.domains.details.dnsRecords.form.microsoftMXContent.label'
                  )}
                  addonSuffix={
                    <div className="gv-addon" style={{ maxWidth: '50%' }}>
                      <p className="gv-text-truncate">{'.mail.protection.outlook.com'}</p>
                    </div>
                  }
                />
              </>
            )}

            <ul
              style={{
                listStyleType: 'disc',
                listStylePosition: 'inside',
              }}
            >
              <p className="gv-text-sm gv-text-bold gv-mb-sm">
                {translate('wpone.domains.details.dnsRecords.form.preset.description', {
                  preset: presetValue,
                })}
              </p>
              {recordArr.map((record, index) => (
                <li
                  key={index}
                  className="gv-text-sm"
                >{`${record.name} ${record.type} ${record.content}`}</li>
              ))}
            </ul>
          </>
        );
      case MXRecordPresetEnum.CUSTOM:
        return (
          <>
            <RHFTextField
              name="hostname"
              labelId="hostname"
              label={translate('wpone.domains.details.dnsRecords.form.hostname.label')}
              generalMsg={translate('wpone.domains.details.dnsRecords.form.hostname.message')}
              addonSuffix={
                <div className="gv-addon" style={{ maxWidth: '50%' }}>
                  <p className="gv-text-truncate">{`.${name}`}</p>
                </div>
              }
            />

            <RHFTextField
              name="pointsTo"
              labelId="pointsTo"
              label={translate('wpone.domains.details.dnsRecords.form.pointsTo.label')}
              generalMsg={translate('wpone.domains.details.dnsRecords.form.pointsTo.message')}
            />

            <RHFTextField
              name="priority"
              labelId="priority"
              label={translate('wpone.domains.details.dnsRecords.form.priority.label')}
            />

            <RHFSelect
              name="ttl"
              labelId="ttl"
              label={translate('wpone.domains.details.dnsRecords.form.ttl.label')}
              options={Object.values(TTL).map((option) => ({
                value: option,
                label: translate(
                  `wpone.domains.details.dnsRecords.form.ttl.options.${option.replace(' ', '')}`
                ),
              }))}
            />
          </>
        );
      default:
        return <></>;
    }
  }

  //
  useEffect(() => {
    setFormError(undefined);
    if (presetValue === MXRecordPresetEnum.GOOGLE_WORKSPACE) {
      setRecordArr([
        {
          name: name as string,
          content: 'ASPMX.L.GOOGLE.COM',
          type: DnsRecordType.MX,
          priority: 1,
          ttl: TTL.oneHour,
        },
        {
          name: name as string,
          content: 'ALT1.ASPMX.L.GOOGLE.COM',
          type: DnsRecordType.MX,
          priority: 5,
          ttl: TTL.oneHour,
        },
        {
          name: name as string,
          content: 'ALT2.ASPMX.L.GOOGLE.COM',
          type: DnsRecordType.MX,
          priority: 5,
          ttl: TTL.oneHour,
        },
        {
          name: name as string,
          content: 'ALT3.ASPMX.L.GOOGLE.COM',
          type: DnsRecordType.MX,
          priority: 10,
          ttl: TTL.oneHour,
        },
        {
          name: name as string,
          content: 'ALT4.ASPMX.L.GOOGLE.COM',
          type: DnsRecordType.MX,
          priority: 10,
          ttl: TTL.oneHour,
        },
      ]);
    } else if (presetValue === MXRecordPresetEnum.ZONER_MAIL) {
      setRecordArr([
        {
          name: name as string,
          content: 'mx1.zoner.fi',
          type: DnsRecordType.MX,
          priority: 1,
          ttl: TTL.auto,
        },
        {
          name: name as string,
          content: 'mx2.zoner.fi',
          type: DnsRecordType.MX,
          priority: 1,
          ttl: TTL.auto,
        },
      ]);
    } else if (presetValue === MXRecordPresetEnum.ZONER_WEBHOTEL) {
      setRecordArr([
        {
          name: name as string,
          content: 'mailscanner01.zoner.fi',
          type: DnsRecordType.MX,
          priority: 1,
          ttl: TTL.auto,
        },
        {
          name: name as string,
          content: 'mailscanner02.zoner.fi',
          type: DnsRecordType.MX,
          priority: 1,
          ttl: TTL.auto,
        },
      ]);
    } else if (presetValue === MXRecordPresetEnum.HOSTNET_MAIL) {
      setRecordArr([
        {
          name: name as string,
          content: 'mailfilter.hostnet.nl',
          type: DnsRecordType.MX,
          priority: 1,
          ttl: TTL.auto,
        },
        {
          name: `default._domainkey.${name}`,
          content: `v=DKIM1; p=${hostnetDkimKeyValue?.trim()}`,
          type: DnsRecordType.TXT,
          ttl: TTL.auto,
        },
        {
          name: name as string,
          content: `v=spf1 include:_spf.hostnet.nl include:smtp.wp-cloud.fi -all`,
          type: DnsRecordType.TXT,
          ttl: TTL.auto,
        },
        {
          name: name as string,
          content: `v=DMARC1; p=reject`,
          type: DnsRecordType.TXT,
          ttl: TTL.auto,
        },
        {
          name: `_autodiscover._tcp.${name}`,
          content: '10 443 autodiscover.hostnet.nl',
          type: DnsRecordType.SRV,
          ttl: TTL.auto,
          priority: 40,
        },
        {
          name: `autoconfig.${name}`,
          content: 'autodiscover.hostnet.nl',
          type: DnsRecordType.CNAME,
          ttl: TTL.auto,
          proxied: false,
        },
        {
          name: `webmail.${name}`,
          content: 'ox.hostnet.nl',
          type: DnsRecordType.CNAME,
          ttl: TTL.auto,
          proxied: false,
        },
      ]);
    } else if (presetValue === MXRecordPresetEnum.MICROSOFT_365) {
      setRecordArr([
        {
          name: name as string,
          content: `${microsoftCustomerIdValue?.trim()}`,
          type: DnsRecordType.TXT,
          ttl: TTL.oneHour,
        },
        {
          name: name as string,
          content: `${microsoftMXContentValue?.trim()}.mail.protection.outlook.com`,
          type: DnsRecordType.MX,
          priority: 5,
          ttl: TTL.oneHour,
        },
        {
          name: `autodiscover.${name}`,
          content: 'autodiscover.outlook.com',
          type: DnsRecordType.CNAME,
          ttl: TTL.oneHour,
          proxied: false,
        },
        {
          name: `lyncdiscover.${name}`,
          content: 'webdir.online.lync.com',
          type: DnsRecordType.CNAME,
          ttl: TTL.oneHour,
          proxied: false,
        },
        {
          name: `sip.${name}`,
          content: 'sipdir.online.lync.com',
          type: DnsRecordType.CNAME,
          ttl: TTL.oneHour,
          proxied: false,
        },
        {
          name: `enterpriseregistration.${name}`,
          content: 'enterpriseregistration.windows.net',
          type: DnsRecordType.CNAME,
          ttl: TTL.oneHour,
          proxied: false,
        },
        {
          name: `enterpriseenrollment.${name}`,
          content: 'enterpriseenrollment.manage.microsoft.com',
          type: DnsRecordType.CNAME,
          ttl: TTL.oneHour,
          proxied: false,
        },
        {
          name: `sip._tls.${name}`,
          content: '1 443 sipdir.online.lync.com',
          type: DnsRecordType.SRV,
          ttl: TTL.auto,
          priority: 100,
        },
        {
          name: `_sipfederationtls._tcp.${name}`,
          content: '1 5061 sipfed.online.lync.com',
          type: DnsRecordType.SRV,
          ttl: TTL.auto,
          priority: 100,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetValue, microsoftCustomerIdValue, microsoftMXContentValue, hostnetDkimKeyValue]);

  return (
    <FormProvider methods={methods}>
      <div className="gv-flex-column-md">
        <RHFSelect
          name="preset"
          label={translate('wpone.domains.details.dnsRecords.form.preset.label')}
          labelId="preset"
          options={getPresetArr(user).map((option) => ({
            value: option,
            label: translate(
              `wpone.domains.details.dnsRecords.form.preset.options.${
                option.charAt(0).toLowerCase() + option.replace(/\s/g, '').slice(1)
              }`
            ),
          }))}
        />

        {displayPresetForm()}

        <ButtonGroup>
          <Button
            uiType="cancel"
            text={translate('wpone.general.action.cancel')}
            onClick={() => navigate(`/dns/records/${name}`)}
          />

          <Button
            text={translate('wpone.domains.details.dnsRecords.addRecord.action')}
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          />
        </ButtonGroup>
      </div>
    </FormProvider>
  );
}
