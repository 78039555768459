import { Dispatch, SetStateAction, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// redux
import { useDispatch } from 'src/redux/store';
import { useAddDnsRecordMutation } from 'src/redux/api/domainApi';
import { addRecordAddedDomain } from 'src/redux/features/domain';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @types
import { DnsRecordType, FormattedCreateDNSRequestBodyDTO, TTL } from 'src/@types/domain';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFSelect, RHFTextField, RHFToggle } from 'src/components/gravity/hook-form';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Button from 'src/components/gravity/Button';

// ----------------------------------------------------------------------

type FormValuesProps = {
  hostname: string;
  cdn: boolean;
  ipv6: string;
  ttl: TTL;
  afterSubmit?: string;
};

type Props = {
  isBunnyDns: boolean;
  setFormError: Dispatch<SetStateAction<string | undefined>>;
};

// ----------------------------------------------------------------------

export default function AddRecordAAAAForm({ isBunnyDns, setFormError }: Props) {
  const { name } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [addDnsRecord, { isLoading }] = useAddDnsRecordMutation();

  // FORM
  const FormSchema = Yup.object().shape({
    ipv6: Yup.string()
      .trim()
      .required(translate('wpone.domains.details.dnsRecords.form.ipv6.validation')),
  });

  const defaultValues = {
    hostname: '',
    cdn: false,
    ipv6: '',
    ttl: TTL.auto,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const { watch, setValue, handleSubmit } = methods;

  const cdnValue = watch('cdn');

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    setFormError(undefined);

    if (!name) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: `add-dns-record`,
    });

    const newDnsRecord: FormattedCreateDNSRequestBodyDTO = {
      type: DnsRecordType.AAAA,
      name: data.hostname.trim() ? `${data.hostname.trim()}.${name}` : `${name}`,
      proxied: data.cdn,
      content: data.ipv6.trim(),
      ttl: data.ttl,
    };

    await addDnsRecord({
      domainName: name,
      newDnsRecord,
      isBunnyDns: isBunnyDns,
    })
      .unwrap()
      .then((data) => {
        if (data) {
          if (isMountedRef.current) {
            navigate(`/dns/records/${name}`);
            dispatch(addRecordAddedDomain(name));
          } else {
            displayToast(
              translate('wpone.domains.details.dnsRecords.addRecord.requestResponse.successMessage')
            );
          }
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        if (isMountedRef.current) {
          setFormError(
            translate(
              'wpone.domains.details.dnsRecords.addRecord.requestResponse.errorMessage.singleRecord'
            )
          );
        } else {
          displayToast(
            translate(
              'wpone.domains.details.dnsRecords.addRecord.requestResponse.errorMessage.singleRecord'
            ),
            { variant: 'alert' }
          );
        }
      });
  };

  //
  useEffect(() => {
    if (cdnValue) {
      setValue('ttl', TTL.auto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdnValue]);

  return (
    <FormProvider methods={methods}>
      <div className="gv-flex-column-md">
        <RHFTextField
          name="hostname"
          labelId="hostname"
          label={translate('wpone.domains.details.dnsRecords.form.hostname.label')}
          generalMsg={translate('wpone.domains.details.dnsRecords.form.hostname.message')}
          addonSuffix={
            <div className="gv-addon" style={{ maxWidth: '50%' }}>
              <p className="gv-text-truncate">{`.${name}`}</p>
            </div>
          }
        />

        {/* Only display CDN if this is NOT Bunny */}
        {!isBunnyDns && (
          <RHFToggle
            name="cdn"
            labelId="cdn"
            label={translate('wpone.domains.details.dnsRecords.form.cdn.label')}
            description={translate('wpone.domains.details.dnsRecords.form.cdn.description')}
            condensed
          />
        )}

        <RHFTextField
          name="ipv6"
          labelId="ipv6"
          label={translate('wpone.domains.details.dnsRecords.form.ipv6.label')}
        />

        <RHFSelect
          name="ttl"
          labelId="ttl"
          label={translate('wpone.domains.details.dnsRecords.form.ttl.label')}
          disabled={cdnValue}
          options={Object.values(TTL).map((option) => ({
            value: option,
            label: translate(
              `wpone.domains.details.dnsRecords.form.ttl.options.${option.replace(' ', '')}`
            ),
          }))}
        />

        <ButtonGroup>
          <Button
            uiType="cancel"
            text={translate('wpone.general.action.cancel')}
            onClick={() => navigate(`/dns/records/${name}`)}
          />

          <Button
            text={translate('wpone.domains.details.dnsRecords.addRecord.action')}
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          />
        </ButtonGroup>
      </div>
    </FormProvider>
  );
}
