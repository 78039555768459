import { useParams, useNavigate } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { dispatch, useSelector } from 'src/redux/store';
import { domainApi } from 'src/redux/api/domainApi';
import { userApi } from 'src/redux/api/userApi';
import { useRevokeZoneUserMutation } from 'src/redux/api/resourceApi';
// @types
import { AccessPermissionFullExpandedDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
import { UserRoleEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// form
import { useForm } from 'react-hook-form';
// components
import { FormProvider } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import AlertBanner from 'src/components/AlertBanner';

// ----------------------------------------------------------------------

type FormValuesProps = {
  afterSubmit?: string;
};

type Props = {
  accessPermissionInfo: AccessPermissionFullExpandedDTO | null;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function RevokeAccessForm({ accessPermissionInfo, onClose }: Props) {
  const { name } = useParams();
  const navigate = useNavigate();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef(); 

  // STATE
  const { user } = useSelector((state) => state.auth);

  // API
  const [revokeZoneUser, { isLoading }] = useRevokeZoneUserMutation();

  // FORM
  const methods = useForm<FormValuesProps>();

  const {
    handleSubmit,
    clearErrors,
    setError,
    formState: { errors },
  } = methods;

  // EVENT HANDLER
  const onSubmit = () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'revoke-zone-access',
    });

    revokeZoneUser({
      zoneName: name || '',
      userEmail: accessPermissionInfo?.user.email || '',
    })
      .unwrap()
      .then((data) => {
        if (data?.success) {
          displayToast(
            translate('wpone.domains.details.users.removeUserDialog.requestResponse.successMessage')
          );

          if (
            user?.role === UserRoleEnum.customer &&
            user.email === accessPermissionInfo?.user.email
          ) {
            navigate('/dns');
            // For getting latest update on Domain table (if user is customer)
            dispatch(domainApi.util.invalidateTags([{ type: 'Domains', id: 'PARTIAL-LIST' }]));
          }

          if (user?.role === UserRoleEnum.support || user?.role === UserRoleEnum.serviceAccount) {
            // For getting latest update on Customer table
            dispatch(userApi.util.invalidateTags([{ type: 'User', id: 'PARTIAL-LIST' }]));
          }
          onClose();
        } else {
          if (isMountedRef.current) {
            setError('afterSubmit', {
              message: translate('wpone.general.requestResponse.errorMessage.other'),
            });
          } else {
            displayToast(translate('wpone.general.requestResponse.errorMessage.other'), {
              variant: 'alert',
            });
          }
        }
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(convertStatusCode(errCode)),
          });
        } else {
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        }
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.domains.details.users.removeUserDialog.title', {
            email: accessPermissionInfo?.user.email,
          })}
        </h2>

        <FormProvider methods={methods}>
          <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
            <p>{translate('wpone.domains.details.users.removeUserDialog.description')}</p>

            {!!errors.afterSubmit && (
              <AlertBanner severity="error">{errors.afterSubmit.message}</AlertBanner>
            )}
          </div>
        </FormProvider>
      </div>

      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.domains.details.users.removeUserDialog.action')}
          onClick={() => {
            clearErrors();
            handleSubmit(onSubmit)();
          }}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
