// @types
import { UpdateDetailsTypeEnum, UpdateManualInfoRowProps } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';

// ----------------------------------------------------------------------

type Props = {
  row: UpdateManualInfoRowProps;
  handleSelectRow: (id: number, checked: boolean) => void;
};

// ----------------------------------------------------------------------

export default function UpdateManualTableRow({ row, handleSelectRow }: Props) {
  // HOOK
  const { translate } = useLocales();

  // VAR
  const { id, selected, details } = row;

  return (
    <tr>
      <td>
        <input
          type="checkbox"
          className="gv-checkbox"
          checked={selected}
          onChange={(e) => handleSelectRow(id, e.target.checked)}
        />
      </td>
      <td>{details.type === UpdateDetailsTypeEnum.CORE ? 'WordPress Core' : details.title}</td>
      <td>{translate(`dashboard.sites.details.mwp.updates.type.${details.type}`)}</td>
      <td>
        <div className="gv-flex gv-flex-row gv-items-center" style={{ gap: '4px' }}>
          <p>{details.version_from}</p>
          <gv-icon src="/src/icons/arrow_forward.svg" size="small" />
          <p>{details.version_to}</p>
        </div>
      </td>
    </tr>
  );
}
