import { ReactNode, useEffect } from 'react';
import { useParams, useLocation } from 'react-router';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { removeRecordAddedDomain, removeRecordEditedDomain } from 'src/redux/features/domain';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// ----------------------------------------------------------------------
// Detailed documentation for details page status banner handler can be checked in StatusBannerHandler for site

export default function DnsDetailsStatusBannerHandler({ children }: Props) {
  const { name } = useParams();

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  // STATE
  const { recordAddedDomains, recordEditedDomains } = useSelector((state) => state.domain);

  // VAR
  const pathnameArr = pathname.split('/');
  const tabname = pathnameArr[2];
  const subPageId = pathnameArr[pathnameArr.length - 1]; // For trigger rerender when navigating inside the same tab (between sub pages)

  const recordAddedDomain = recordAddedDomains.find((domainName) => domainName === name);

  const recordEditedDomain = recordEditedDomains.find((domainName) => domainName === name);

  //
  useEffect(
    () => () => {
      if (name) {
        if (tabname === 'records') {
          if (recordAddedDomain) {
            dispatch(removeRecordAddedDomain(name));
          }

          if (recordEditedDomain) {
            dispatch(removeRecordEditedDomain(name));
          }
        }
      }
    },
    [dispatch, name, tabname, recordAddedDomain, recordEditedDomain, subPageId]
  );

  return <>{children}</>;
}
