// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import DeleteDomainConfirmation from './DeleteDomainConfirmation';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteDomainDialog({ open, onClose }: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={<DeleteDomainConfirmation onClose={onClose} />}
    />
  );
}
