import { Suspense, lazy, ElementType } from 'react';
import { Navigate, useLocation, createBrowserRouter } from 'react-router-dom';
// @types
import { UserRoleEnum } from 'src/@types/user';
// layouts
import LogoLayout from 'src/layouts/LogoLayout';
import MainLayout from 'src/layouts/main';
import SiteDetailsLayout from 'src/layouts/gravity/sites/SiteDetailsLayout';
import DnsDetailsLayout from 'src/layouts/gravity/dns/DnsDetailsLayout';
// guards
import GuestGuard from 'src/guards/GuestGuard';
import AuthGuard from 'src/guards/AuthGuard';
import PageGuard from 'src/guards/PageGuard';
import PageDetailsTabGuard from 'src/guards/PageDetailsTabGuard';
import SiteDetailsGuard from 'src/guards/SiteDetailsGuard';
import SiteBackupDetailsGuard from 'src/guards/SiteBackupDetailsGuard';
import { MWPUpdatesManualPageGuard } from 'src/guards/SiteDetailsUpdateSubPageGuard';
import CreateSitePlanGuard from 'src/guards/CreateSitePlanGuard';
import ChangeSitePlanGuard from 'src/guards/ChangeSitePlanGuard';
import DnsDetailsGuard from 'src/guards/DnsDetailsGuard';
import DomainDetailsEditRecordPageGuard from 'src/guards/DomainDetailsEditRecordPageGuard';
import MWPTestingSiteGuard from 'src/guards/MWPTestingSiteGuard';
// config
import { ACCOUNT_SETTINGS_NAV_CONFIG, SUPPORT_DATA_NAV_CONFIG } from 'src/config';
// sections
import { SiteDetailsStatusBannerHandler } from 'src/sections/sites/details';
import { DnsDetailsStatusBannerHandler } from 'src/sections/dns/details';
// components
import LoadingScreen from 'src/components/LoadingScreen';
import ScrollToTop from 'src/components/ScrollToTop';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/sites')} />}>
      <Component {...props} />
    </Suspense>
  );
};

// AUTH
const InitialAuth = Loadable(lazy(() => import('src/pages/auth/InitialAuth')));
const Login = Loadable(lazy(() => import('src/pages/auth/Login')));
const CheckEmail = Loadable(lazy(() => import('src/pages/auth/CheckEmail')));
// SITES
const Sites = Loadable(lazy(() => import('src/pages/sites/Sites')));
const PlanSelection = Loadable(lazy(() => import('src/pages/sites/SitePlanSelection')));
const TableCustomize = Loadable(lazy(() => import('src/pages/sites/TableCustomize')));
const SiteMigration = Loadable(lazy(() => import('src/pages/sites/SiteMigration')));
// Sites details
const SitePageSeparator = Loadable(lazy(() => import('src/pages/sites/details/SitePageSeparator')));
// Site details (WP.one)
const SiteSettings = Loadable(lazy(() => import('src/pages/sites/details/wpone/SiteSettings')));
const SiteDomains = Loadable(lazy(() => import('src/pages/sites/details/wpone/SiteDomains')));
const SiteUsers = Loadable(lazy(() => import('src/pages/sites/details/wpone/SiteUsers')));
const SiteStatistics = Loadable(lazy(() => import('src/pages/sites/details/wpone/SiteStatistics')));
const SiteVulnerabilities = Loadable(
  lazy(() => import('src/pages/sites/details/wpone/SiteVulnerabilities'))
);
const SiteBackups = Loadable(lazy(() => import('src/pages/sites/details/wpone/SiteBackups')));
const SitePlan = Loadable(lazy(() => import('src/pages/sites/details/wpone/SitePlan')));
const SiteSupport = Loadable(lazy(() => import('src/pages/sites/details/wpone/SiteSupport')));
// Sites details nested page
const SiteBackupDetails = Loadable(
  lazy(() => import('src/pages/sites/details/wpone/SiteBackupDetails'))
);
// Site details (mWP)
const SitePerformace = Loadable(lazy(() => import('src/pages/sites/details/mwp/SitePerformance')));
const SiteSecurity = Loadable(
  lazy(() => import('src/pages/sites/details/mwp/security/SiteSecurity'))
);
// Sites details nested page
const SiteSecuritySettings = Loadable(
  lazy(() => import('src/pages/sites/details/mwp/security/SiteSecuritySettings'))
);
const SiteSecurityVulnerabilityLog = Loadable(
  lazy(() => import('src/pages/sites/details/mwp/security/SiteSecurityVulnerabilityLog'))
);
const SiteUpdateManual = Loadable(
  lazy(() => import('src/pages/sites/details/mwp/updates/SiteUpdateManual'))
);
// DNS
const Dns = Loadable(lazy(() => import('src/pages/dns/Dns')));
const DnsRecords = Loadable(lazy(() => import('src/pages/dns/details/records/DnsRecords')));
const DnsAddRecord = Loadable(lazy(() => import('src/pages/dns/details/records/DnsAddRecord')));
const DnsEditRecord = Loadable(lazy(() => import('src/pages/dns/details/records/DnsEditRecord')));
const DnsSettings = Loadable(lazy(() => import('src/pages/dns/details/DnsSettings')));
const DnsUsers = Loadable(lazy(() => import('src/pages/dns/details/DnsUsers')));
// CUSTOMERS
const Customers = Loadable(lazy(() => import('src/pages/customers/Customers')));
const AddSitePermission = Loadable(lazy(() => import('src/pages/customers/AddSitePermission')));
const ChangePermission = Loadable(lazy(() => import('src/pages/customers/ChangePermission')));
// CHANGE HISTORY
const ChangeHistory = Loadable(lazy(() => import('src/pages/plan/ChangeHistory')));
// DATA
const Data = Loadable(lazy(() => import('src/pages/data')));
// SETTINGS
const SettingDetails = Loadable(lazy(() => import('src/pages/settings')));
// ERROR
const Forbidden = Loadable(lazy(() => import('src/pages/Page403')));
const NotFound = Loadable(lazy(() => import('src/pages/Page404')));

// ----------------------------------------------------------------------

const loginRoutes = [
  {
    path: '/',
    children: [
      { element: <Navigate to="/login" replace />, index: true },
      {
        path: 'login',
        children: [
          {
            element: (
              <GuestGuard>
                <Login />
              </GuestGuard>
            ),
            index: true,
          },
          {
            path: 'check-email',
            element: (
              <GuestGuard>
                <CheckEmail />
              </GuestGuard>
            ),
          },
        ],
      },
    ],
  },
];

const authRoutes = [
  {
    path: '/auth',
    element: (
      <GuestGuard>
        <InitialAuth />
      </GuestGuard>
    ),
  },
];

const siteRoutes = [
  {
    path: 'sites',
    children: [
      { element: <Sites />, index: true },
      {
        path: 'customize',
        element: <TableCustomize />,
      },
      {
        path: 'create-site',
        element: (
          <CreateSitePlanGuard>
            <PlanSelection />
          </CreateSitePlanGuard>
        ),
      },
      {
        path: ':cluster/:namespace/:name/change-plan',
        element: (
          <ChangeSitePlanGuard>
            <PlanSelection />
          </ChangeSitePlanGuard>
        ),
      },
      {
        path: 'migration',
        element: <SiteMigration />,
      },
      // Site details paths
      {
        element: (
          <SiteDetailsGuard>
            <SiteDetailsStatusBannerHandler>
              <SiteDetailsLayout />
            </SiteDetailsStatusBannerHandler>
          </SiteDetailsGuard>
        ),
        children: [
          // Both
          {
            path: 'overview/:cluster/:namespace/:name',
            element: <SitePageSeparator separator="overview" />,
          },
          {
            path: 'updates/:cluster/:namespace/:name',
            children: [
              {
                element: <SitePageSeparator separator="updates" />,
                index: true,
              },
              {
                path: ':updateReportId',
                children: [
                  {
                    element: <SitePageSeparator separator="updates" />,
                    index: true,
                  },
                  {
                    path: ':updateDetailsName',
                    element: <SitePageSeparator separator="updates" />,
                  },
                ],
              },
              {
                path: 'manual-update',
                element: (
                  <MWPUpdatesManualPageGuard>
                    <SiteUpdateManual />
                  </MWPUpdatesManualPageGuard>
                ),
              },
            ],
          },
          // WP.one
          {
            path: 'settings/:cluster/:namespace/:name',
            element: <SiteSettings />,
          },
          {
            path: 'domains/:cluster/:namespace/:name',
            element: <SiteDomains />,
          },
          {
            path: 'users/:cluster/:namespace/:name',
            element: <SiteUsers />,
          },
          {
            path: 'statistics/:cluster/:namespace/:name',
            element: <SiteStatistics />,
          },
          {
            path: 'vulnerabilities/:cluster/:namespace/:name',
            element: <SiteVulnerabilities />,
          },
          {
            path: 'backups/:cluster/:namespace/:name',
            children: [
              {
                element: <SiteBackups />,
                index: true,
              },
              {
                path: 'details',
                element: (
                  <SiteBackupDetailsGuard>
                    <SiteBackupDetails />
                  </SiteBackupDetailsGuard>
                ),
              },
            ],
          },
          {
            path: 'plan/:cluster/:namespace/:name',
            element: <SitePlan />,
          },
          {
            path: 'support/:cluster/:namespace/:name',
            element: <SiteSupport />,
          },
          // mWP
          {
            path: 'performance/:cluster/:namespace/:name',
            element: <SitePerformace />,
          },
          {
            path: 'security/:cluster/:namespace/:name',
            children: [
              {
                element: <SiteSecurity />,
                index: true,
              },
              {
                path: 'settings',
                element: (
                  <MWPTestingSiteGuard>
                    <SiteSecuritySettings />
                  </MWPTestingSiteGuard>
                ),
              },
              {
                path: 'vulnerability-log',
                element: (
                  <MWPTestingSiteGuard>
                    <SiteSecurityVulnerabilityLog />
                  </MWPTestingSiteGuard>
                ),
              },
            ],
          },
        ],
      },
      {
        path: 'plan/:cluster/:namespace/:name/change-plan',
        element: (
          <SiteDetailsGuard>
            <ChangeSitePlanGuard>
              {/* /change-plan layout don't need sidebar Outlet */}
              <PlanSelection />
            </ChangeSitePlanGuard>
          </SiteDetailsGuard>
        ),
      },
    ],
  },
];

const dnsRoutes = [
  {
    path: 'dns',
    children: [
      { element: <Dns />, index: true },
      {
        element: (
          <DnsDetailsGuard>
            <DnsDetailsStatusBannerHandler>
              <DnsDetailsLayout />
            </DnsDetailsStatusBannerHandler>
          </DnsDetailsGuard>
        ),
        children: [
          {
            path: 'records/:name',
            children: [
              {
                element: <DnsRecords />,
                index: true,
              },
              {
                path: 'add',
                element: <DnsAddRecord />,
              },
              {
                path: ':recordId/edit',
                element: (
                  <DomainDetailsEditRecordPageGuard>
                    <DnsEditRecord />
                  </DomainDetailsEditRecordPageGuard>
                ),
              },
            ],
          },
          {
            path: 'settings/:name',
            element: <DnsSettings />,
          },
          {
            path: 'users/:name',
            element: <DnsUsers />,
          },
        ],
      },
    ],
  },
];

const customerRoutes = [
  {
    path: 'customers',
    children: [
      {
        element: (
          <PageGuard allowRoles={[UserRoleEnum.serviceAccount, UserRoleEnum.support]}>
            <Customers />
          </PageGuard>
        ),
        index: true,
      },
      {
        path: 'permission',
        element: (
          <PageGuard allowRoles={[UserRoleEnum.serviceAccount, UserRoleEnum.support]}>
            <AddSitePermission />
          </PageGuard>
        ),
      },
      {
        path: ':email',
        element: (
          <PageGuard allowRoles={[UserRoleEnum.serviceAccount, UserRoleEnum.support]}>
            <ChangePermission />
          </PageGuard>
        ),
      },
    ],
  },
];

const otherRoutes = [
  {
    path: 'plan-change-history',
    element: (
      <PageGuard allowRoles={[UserRoleEnum.serviceAccount, UserRoleEnum.support]}>
        <ChangeHistory />
      </PageGuard>
    ),
  },
  {
    path: 'data/:tabname',
    element: (
      <PageDetailsTabGuard navConfig={SUPPORT_DATA_NAV_CONFIG}>
        <Data />
      </PageDetailsTabGuard>
    ),
  },
  {
    path: 'settings/:tabname',
    element: (
      <PageDetailsTabGuard navConfig={ACCOUNT_SETTINGS_NAV_CONFIG}>
        <SettingDetails />
      </PageDetailsTabGuard>
    ),
  },
];

const errorRoutes = [
  {
    path: '*',
    element: <LogoLayout />,
    children: [
      { path: '403', element: <Forbidden /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
  },
  { path: '*', element: <Navigate to="/404" replace /> },
];

const router = createBrowserRouter([
  ...loginRoutes,
  ...authRoutes,
  {
    path: '/',
    element: (
      <AuthGuard>
        <MainLayout />
        <ScrollToTop />
      </AuthGuard>
    ),
    children: [...siteRoutes, ...dnsRoutes, ...customerRoutes, ...otherRoutes],
  },
  ...errorRoutes,
]);

export default router;
