// @types
import { ProgressStepStatusEnum } from 'src/@types/layout';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
//
import Loader from './Loader';

// ----------------------------------------------------------------------

type Props = {
  status: ProgressStepStatusEnum;
  text: string;
};

// ----------------------------------------------------------------------

export default function ProgressStep({ status, text }: Props) {
  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  return (
    <div
      className="gv-flex gv-justify-between gv-py-sm gv-px-md gv-radius"
      style={{
        backgroundColor: 'var(--color-surface-dim)',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: isMobile ? 'flex-start' : 'center',
        gap: isMobile ? '4px' : '16px',
      }}
    >
      <div className="gv-flex-row-sm gv-items-center">
        {status === ProgressStepStatusEnum.IN_PROGRESS ? (
          <Loader condensed />
        ) : (
          <gv-icon
            src={`/src/icons/${
              status === ProgressStepStatusEnum.WAITING ? 'remove' : 'check_circle'
            }.svg`}
          />
        )}
        <p className="gv-text-sm">{translate(text)}</p>
      </div>
      <div className="gv-flex-row-sm">
        {isMobile && (
          <div
            style={{
              width: 24,
              opacity: 0,
            }}
          />
        )}
        <div
          className={`gv-badge gv-badge-${
            status === ProgressStepStatusEnum.IN_PROGRESS
              ? 'info'
              : status === ProgressStepStatusEnum.WAITING
              ? 'generic'
              : 'success'
          }`}
        >
          {translate(`wpone.general.status.${status}`)}
        </div>
      </div>
    </div>
  );
}
