import { Dispatch, SetStateAction } from 'react';
// redux
import { useSelector } from 'src/redux/store';
// @types
import { AccessPermissionFullExpandedDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
import { AccessTypeEnum, UserRoleEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { isHostnetCustomer } from 'src/utils/check';
// components
import { TableMoreMenu } from 'src/components/gravity/table';

// ----------------------------------------------------------------------

type Props = {
  row: AccessPermissionFullExpandedDTO;
  ownerAccessPermissionInfo: AccessPermissionFullExpandedDTO | null;
  setAccessPermissionInfo: Dispatch<SetStateAction<AccessPermissionFullExpandedDTO | null>>;
  onOpenRevokeAccessDialog: VoidFunction;
  onOpenTransferOwnershipDialog: VoidFunction;
};

// ----------------------------------------------------------------------

export default function UserTableRow({
  row,
  ownerAccessPermissionInfo,
  setAccessPermissionInfo,
  onOpenRevokeAccessDialog,
  onOpenTransferOwnershipDialog,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const { user } = useSelector((state) => state.auth);

  // VAR
  const { accessType, user: userRow, isRevokable } = row;

  // EVENT FUNCTION
  function handleRevokePermissions() {
    setAccessPermissionInfo(row);
    onOpenRevokeAccessDialog();
  }

  function handleTransferOwnership() {
    setAccessPermissionInfo(row);
    onOpenTransferOwnershipDialog();
  }

  // HELPER FUNCTION
  function showMoreMenuHandler() {
    if (
      row.accessType !== AccessTypeEnum.owner &&
      (isRevokable ||
        user?.role === UserRoleEnum.serviceAccount ||
        user?.role === UserRoleEnum.support ||
        (user?.email === ownerAccessPermissionInfo?.user.email && !isHostnetCustomer(user)))
    ) {
      // If this user is not the owner AND not revokable (also mean not transferable) then MoreMenu will not be displayed
      return true;
    }
    return false;
  }

  function showTransferOwnershipOption(currentUserRole: UserRoleEnum | undefined) {
    if (
      currentUserRole === UserRoleEnum.support ||
      currentUserRole === UserRoleEnum.serviceAccount ||
      (user?.email === ownerAccessPermissionInfo?.user.email && !isHostnetCustomer(user))
      // If this user is a customer and has an owner role AND not hostnet customer then TransferOwnership option will be displayed
    )
      return true;

    return false;
  }

  return (
    <tr>
      <td>{userRow.name}</td>
      <td>{userRow.email}</td>
      <td>
        <div className="gv-flex gv-flex-col">
          <p>{translate(`wpone.domains.details.users.table.userRole.${accessType}`)}</p>
          {!isRevokable && row.accessType !== AccessTypeEnum.owner && (
            <p className="gv-caption-sm gv-text-on-alternative">
              {translate('wpone.domains.details.users.table.row.role')}
            </p>
          )}
        </div>
      </td>

      <td>
        {showMoreMenuHandler() && (
          <TableMoreMenu
            sections={[
              {
                items: [
                  ...(isRevokable
                    ? [
                        {
                          icon: 'delete',
                          text: translate('wpone.domains.details.users.table.row.moreMenu.remove'),
                          function: handleRevokePermissions,
                        },
                      ]
                    : []),
                  ...(showTransferOwnershipOption(user?.role)
                    ? [
                        {
                          icon: 'sync_alt',
                          text: translate(
                            'wpone.domains.details.users.table.row.moreMenu.transfer'
                          ),
                          function: handleTransferOwnership,
                        },
                      ]
                    : []),
                ],
              },
            ]}
          />
        )}
      </td>
    </tr>
  );
}
