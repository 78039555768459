import { useState } from 'react';
// @types
import { DnsRecordType } from 'src/@types/domain';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import { Select } from 'src/components/gravity/form';
import Alert from 'src/components/gravity/Alert';
//
import AddRecordAForm from './add-record-form/AddRecordAForm';
import AddRecordAAAAForm from './add-record-form/AddRecordAAAAForm';
import AddRecordCNAMEForm from './add-record-form/AddRecordCNAMEForm';
import AddRecordMXForm from './add-record-form/AddRecordMXForm';
import AddRecordSRVForm from './add-record-form/AddRecordSRVForm';
import AddRecordTXTForm from './add-record-form/AddRecordTXTForm';

// ----------------------------------------------------------------------

type Props = {
  isBunnyDns: boolean;
};

// ----------------------------------------------------------------------

export default function AddDnsRecordForm({ isBunnyDns }: Props) {
  // HOOK
  const { translate } = useLocales();

  // STATE
  const [recordType, setRecordType] = useState<DnsRecordType>(DnsRecordType.A);

  const [formError, setFormError] = useState<string | undefined>(undefined);

  // HELPER FUNCTION
  function displayForm() {
    switch (recordType) {
      case DnsRecordType.A:
        return <AddRecordAForm isBunnyDns={isBunnyDns} setFormError={setFormError} />;
      case DnsRecordType.AAAA:
        return <AddRecordAAAAForm isBunnyDns={isBunnyDns} setFormError={setFormError} />;
      case DnsRecordType.CNAME:
        return <AddRecordCNAMEForm isBunnyDns={isBunnyDns} setFormError={setFormError} />;
      case DnsRecordType.MX:
        return <AddRecordMXForm isBunnyDns={isBunnyDns} setFormError={setFormError} />;
      case DnsRecordType.SRV:
        return <AddRecordSRVForm isBunnyDns={isBunnyDns} setFormError={setFormError} />;
      case DnsRecordType.TXT:
        return <AddRecordTXTForm isBunnyDns={isBunnyDns} setFormError={setFormError} />;
      default:
        return <></>;
    }
  }

  return (
    <div className="gv-flex-column-lg">
      {formError && <Alert type="alert" text={formError} />}

      <div className="gv-content-container gv-flex-column-md gv-p-lg">
        <Select
          labelId="recordType"
          label={translate('wpone.domains.details.dnsRecords.form.recordType.label')}
          options={Object.values(DnsRecordType).map((type) => ({ label: type, value: type }))}
          onChange={(e) => setRecordType(e.target.value as DnsRecordType)}
        />

        {displayForm()}
      </div>
    </div>
  );
}
