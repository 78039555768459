// @types
import { DeletingRecordsState } from 'src/@types/domain';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import DeleteDnsConfirmation from './DeleteDnsRecordConfirmation';

// ----------------------------------------------------------------------

type Props = {
  isBunnyDns: boolean;
  deletingRecordsState: DeletingRecordsState;
  open: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteDnsRecordDialog({
  isBunnyDns,
  deletingRecordsState,
  open,
  onClose,
}: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <DeleteDnsConfirmation
          isBunnyDns={isBunnyDns}
          deletingRecordsState={deletingRecordsState}
          onClose={onClose}
        />
      }
    />
  );
}
