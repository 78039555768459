import { Dispatch, SetStateAction, useEffect } from 'react';
import ReactGA from 'react-ga4';
// form
import { useForm } from 'react-hook-form';
// @types
import { SecuritySettings } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
// components
import { FormProvider, RHFCheckbox } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
//
import SettingsBlock from './SettingsBlock';

// ----------------------------------------------------------------------

type FormValuesProps = {
  newVulns: boolean;
  fixedVulns: boolean;
};

type Props = {
  notificationsSettings: {
    newVulns: boolean;
    fixedVulns: boolean;
  };
  isSettingsLoading: boolean;
  setSettings: Dispatch<SetStateAction<SecuritySettings>>;
  setIsSettingsLoading: Dispatch<SetStateAction<boolean>>;
  setSaveNotiSettingsSuccess: Dispatch<SetStateAction<undefined | boolean>>;
};

// ----------------------------------------------------------------------

export default function NotificationsForm({
  notificationsSettings,
  isSettingsLoading,
  setSettings,
  setIsSettingsLoading,
  setSaveNotiSettingsSuccess,
}: Props) {
  // HOOK
  const { translate } = useLocales();

  const defaultValues = {
    newVulns: notificationsSettings.newVulns,
    fixedVulns: notificationsSettings.fixedVulns,
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const { handleSubmit, setValue } = methods;

  //EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'save-notifications-site-security-settings',
    });

    setIsSettingsLoading(true);
    setTimeout(() => {
      setIsSettingsLoading(false);

      const isSuccess: boolean = false;
      setSettings((prev) => {
        const copyObj = {
          newVulns: prev.notifications.newVulns,
          fixedVulns: prev.notifications.fixedVulns,
        };

        return { ...prev, notifications: isSuccess ? data : copyObj };
      });
      setSaveNotiSettingsSuccess(isSuccess);
    }, 2000);
  };

  //
  useEffect(() => {
    setValue('newVulns', notificationsSettings.newVulns);
    setValue('fixedVulns', notificationsSettings.fixedVulns);
  }, [notificationsSettings, setValue]);

  return (
    <SettingsBlock
      title="dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.title"
      description="dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.description"
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <div className="gv-content-container gv-flex-column-md gv-p-lg">
          <div className="gv-flex-column-sm">
            <p className="gv-text-sm gv-text-bold">
              {translate(
                'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.title'
              )}
            </p>
            <div className="gv-flex-column-md">
              <RHFCheckbox
                name="newVulns"
                label={translate(
                  'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.newVulns.label'
                )}
                labelId="notifications-newVulns"
                condensed
              />
              <RHFCheckbox
                name="fixedVulns"
                label={translate(
                  'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.fixedVulns.label'
                )}
                labelId="notifications-fixedVulns"
                description={translate(
                  'dashboard.sites.details.mwp.security.settings.securityNotificationsBlock.form.fixedVulns.description'
                )}
                condensed
              />
            </div>
          </div>

          <div>
            <Button
              type="submit"
              text={translate('dashboard.general.action.save')}
              disabled={isSettingsLoading}
            />
          </div>
        </div>
      </FormProvider>
    </SettingsBlock>
  );
}
