// @types
import { AccessPermissionFullExpandedDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
// components
import ActionDialog from 'src/components/gravity/ActionDialog';
//
import TransferOwnershipForm from './TransferOwnershipForm';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  accessPermissionInfo: AccessPermissionFullExpandedDTO | null;
  ownerAccessPermissionInfo: AccessPermissionFullExpandedDTO | null;
};

// ----------------------------------------------------------------------

export default function TransferOwnershipDialog({
  open,
  onClose,
  accessPermissionInfo,
  ownerAccessPermissionInfo,
}: Props) {
  return (
    <ActionDialog
      open={open}
      onClose={onClose}
      children={
        <TransferOwnershipForm
          onClose={onClose}
          accessPermissionInfo={accessPermissionInfo}
          ownerAccessPermissionInfo={ownerAccessPermissionInfo}
        />
      }
    />
  );
}
