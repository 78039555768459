// @types
import { GetDNSRecordDTO } from '@joonasvanhatapio/wp-cloud-backend-types';

// ----------------------------------------------------------------------

export type DomainState = {
  // Displaying added record banner
  recordAddedDomains: string[];
  // Displaying edited record banner
  recordEditedDomains: string[];
};

export enum DnsRecordType {
  A = 'A',
  AAAA = 'AAAA',
  CNAME = 'CNAME',
  MX = 'MX',
  SRV = 'SRV',
  TXT = 'TXT',
}

export enum TTL {
  auto = 'auto',
  oneHour = '1 hour',
  twoHours = '2 hours',
  fourHours = '4 hours',
  fiveHours = '5 hours',
  sixteenHours = '16 hours',
  oneDay = '1 day',
}

export type FormattedGetDNSRecordDTO = Omit<GetDNSRecordDTO, 'id' | 'ttl'> & {
  id: string;
  ttl: TTL;
};

export type FormattedCreateDNSRequestBodyDTO = {
  name: string;
  type: string;
  content: string;
  ttl: TTL;
  priority?: number;
  proxied?: boolean;
  parentDomain?: string;
  isAdditionalRecord?: boolean;
};

export type FormattedPatchDNSRecordDataBodyDTO = {
  name: string;
  type: string;
  content: string;
  ttl: TTL;
  priority?: number;
  proxied?: boolean;
  parentDomain?: string;
  isAdditionalRecord?: boolean;
};

export type DnsRecordRowProps = {
  selected: boolean;
  record: FormattedGetDNSRecordDTO;
};

export type DeletingRecordsState = {
  records: FormattedGetDNSRecordDTO[];
  allSelected: boolean;
};

export type ImportRecordType = {
  fileContent: string;
  name: string;
  ttl: TTL;
  type: DnsRecordType;
  weight?: string;
  priority?: number;
  port?: string;
  content: string;
};

export enum StateTypeEnum {
  success = 'success',
  error = 'error',
  warning = 'warning',
}
