// @types
import { FormattedGetDNSRecordDTO } from 'src/@types/domain';

// ----------------------------------------------------------------------

export function getRecordDetails(
  recordId: string,
  recordList: FormattedGetDNSRecordDTO[] | undefined
) {
  return recordList === undefined ? undefined : recordList.find((report) => report.id === recordId);
}
