import { TextareaHTMLAttributes, Ref, forwardRef, ReactNode } from 'react';
//
import Tooltip from '../Tooltip';

// ----------------------------------------------------------------------

type IProps = {
  label?: string;
  labelId?: string; // Required for label
  labelOptional?: string;
  labelIcon?: string;
  labelIconTooltip?: string;
  inputErr?: boolean; // Error color apply to input border as well
  errMsg?: string;
  addon?: ReactNode;
  condensed?: boolean;
  disabled?: boolean; //Pass disabled manually to input div wrapper to apply gravity
  readOnly?: boolean; //Pass readyOnly manually to both input and input div wrapper to apply gravity, this will make the input field not editable, gravity doesn't have this feature in React
};

type Props = IProps & TextareaHTMLAttributes<HTMLTextAreaElement>;

// ----------------------------------------------------------------------

const Textarea = forwardRef(
  (
    {
      label,
      labelId,
      labelOptional,
      labelIcon,
      labelIconTooltip,
      inputErr,
      errMsg,
      addon,
      condensed,
      disabled,
      readOnly,
      ...other
    }: Props,
    ref: Ref<HTMLTextAreaElement>
  ) => (
    <div className="gv-activated">
      <div className="gv-form-option">
        {label && labelId && (
          <label htmlFor={labelId} className={`gv-label${labelIcon ? ' gv-label-with-icon' : ''}`}>
            <span>{label}</span>
            {labelOptional && <span className="gv-label-optional">{labelOptional}</span>}
            {labelIcon &&
              (labelIconTooltip ? (
                <Tooltip tooltip={labelIconTooltip} width={300}>
                  <gv-icon src="/src/icons/info.svg" size="small" />
                </Tooltip>
              ) : (
                <gv-icon src={`/src/icons/${labelIcon}.svg`} />
              ))}
          </label>
        )}

        {addon ? (
          <div
            className={`gv-input gv-input-textarea gv-with-addon${readOnly ? ' gv-read-only' : ''}${
              condensed ? ' gv-mode-condensed' : ''
            }${inputErr ? ' gv-error' : ''}${disabled ? ' gv-disabled' : ''}`}
          >
            <textarea
              ref={ref}
              id={labelId ? labelId : undefined}
              {...(readOnly ? { readOnly: true } : {})}
              {...other}
            />
            {addon}
          </div>
        ) : (
          <textarea
            ref={ref}
            id={labelId ? labelId : undefined}
            className={`gv-input gv-input-textarea${readOnly ? ' gv-read-only' : ''}${
              condensed ? ' gv-mode-condensed' : ''
            }${inputErr ? ' gv-error' : ''}${disabled ? ' gv-disabled' : ''}`}
            {...(readOnly ? { readOnly: true } : {})}
            {...other}
          />
        )}

        {errMsg && <span className="gv-input-message gv-error">{errMsg}</span>}
      </div>
    </div>
  )
);

export default Textarea;
