import { useParams } from 'react-router';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// redux
import { useUpdatePHPVersionMutation } from 'src/redux/api/siteApi';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @types
import { PHPVersionEnum } from 'src/@types/site';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Alert from 'src/components/gravity/Alert';
import { FormProvider, RHFSelect } from 'src/components/gravity/hook-form';

// ----------------------------------------------------------------------

type FormValuesProps = {
  phpVersion: number;
  afterSubmit?: string;
};

type Props = {
  currentPhpVersion: number;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function UpdatePHPVersionForm({ currentPhpVersion, onClose }: Props) {
  const { cluster, namespace } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [updatePHPVersion, { isLoading }] = useUpdatePHPVersionMutation();

  // FORM
  const FormSchema = Yup.object().shape({
    phpVersion: Yup.string(),
  });

  const defaultValues = {
    phpVersion: currentPhpVersion,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors },
  } = methods;

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (!cluster || !namespace) return;

    // If new version is the same as current version => Close dialog
    if (Number(data.phpVersion) === currentPhpVersion) {
      onClose();
      return;
    }

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'update-php-version',
    });

    updatePHPVersion({
      cluster: cluster,
      namespace: namespace,
      phpVersion: Number(data.phpVersion),
    })
      .unwrap()
      .then(() => {
        displayToast(
          translate(
            'wpone.sites.details.settings.siteAdministration.changePhp.dialog.requestResponse.successMessage'
          )
        );
        onClose();
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(convertStatusCode(errCode)),
          });
        } else {
          displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        }
      });
  };

  // HELPER FUNCTION
  function getVersionList() {
    // For customer: remove 7.4 and 8.0 from view (still visible but disabled if the current site is using these versions)
    // For support/sa: remove 7.4 and 8.0 from view (still visible but disabled if the current site is using this version)
    // Current version no matter which will be added to the list but disabled

    const result = [PHPVersionEnum.PHP81, PHPVersionEnum.PHP82, PHPVersionEnum.PHP83, PHPVersionEnum.PHP84];

    return [
      currentPhpVersion.toFixed(1) as PHPVersionEnum,
      ...result.filter((ver) => Number(ver) !== currentPhpVersion),
    ];
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.sites.details.settings.siteAdministration.changePhp.dialog.title')}
        </h2>

        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
            {!!errors.afterSubmit?.message && (
              <Alert type="alert" text={errors.afterSubmit.message} />
            )}

            <p>
              {translate(
                'wpone.sites.details.settings.siteAdministration.changePhp.dialog.description'
              )}
            </p>

            <Alert
              type="warning"
              text={translate(
                'wpone.sites.details.settings.siteAdministration.changePhp.dialog.warning'
              )}
            />

            <RHFSelect
              name="phpVersion"
              label={translate(
                'wpone.sites.details.settings.siteAdministration.changePhp.dialog.inputField.label'
              )}
              labelId="php-version"
              options={getVersionList().map((option) => ({
                label: `PHP ${option} ${
                  Number(option) === currentPhpVersion
                    ? `(${translate(
                        'wpone.sites.details.settings.siteAdministration.changePhp.dialog.inputField.option'
                      )})`
                    : ''
                }`,
                value: Number(option),
                disabled: Number(option) === currentPhpVersion,
              }))}
            />
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.general.action.update')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
