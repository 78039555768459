import { useParams } from 'react-router';
// redux
import { useGetDnsRecordsQuery } from 'src/redux/api/domainApi';
// @types
import { DnsRecordType } from 'src/@types/domain';
// utils
import { getRecordDetails } from 'src/utils/getDomainRecordInfo';
//
import EditRecordAForm from './edit-record-form/EditRecordAForm';
import EditRecordAAAAForm from './edit-record-form/EditRecordAAAAForm';
import EditRecordCNAMEForm from './edit-record-form/EditRecordCNAME';
import EditRecordMXForm from './edit-record-form/EditRecordMXForm';
import EditRecordSRVForm from './edit-record-form/EditRecordSRVForm';
import EditRecordTXTForm from './edit-record-form/EditRecordTXTForm';

// ----------------------------------------------------------------------

type Props = {
  isBunnyDns: boolean;
};

// ----------------------------------------------------------------------

export default function EditDnsRecordForm({ isBunnyDns }: Props) {
  const { name, recordId } = useParams();

  // API
  const { data: domainRecords } = useGetDnsRecordsQuery(name);

  // VAR
  const editingRecord = getRecordDetails(recordId as string, domainRecords);

  // HELPER FUNCTION
  function displayForm() {
    switch (editingRecord?.type) {
      case DnsRecordType.A:
        return <EditRecordAForm isBunnyDns={isBunnyDns} editingRecord={editingRecord} />;
      case DnsRecordType.AAAA:
        return <EditRecordAAAAForm isBunnyDns={isBunnyDns} editingRecord={editingRecord} />;
      case DnsRecordType.CNAME:
        return <EditRecordCNAMEForm isBunnyDns={isBunnyDns} editingRecord={editingRecord} />;
      case DnsRecordType.MX:
        return <EditRecordMXForm isBunnyDns={isBunnyDns} editingRecord={editingRecord} />;
      case DnsRecordType.SRV:
        return <EditRecordSRVForm isBunnyDns={isBunnyDns} editingRecord={editingRecord} />;
      case DnsRecordType.TXT:
        return <EditRecordTXTForm isBunnyDns={isBunnyDns} editingRecord={editingRecord} />;
      default:
        return <></>;
    }
  }

  return <div className="gv-content-container gv-flex-column-md gv-p-lg">{displayForm()}</div>;
}
