import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router';
// redux
import { useGetDnsRecordsQuery } from 'src/redux/api/domainApi';
// utils
import { getRecordDetails } from 'src/utils/getDomainRecordInfo';
// components
import LoadingScreen from 'src/components/LoadingScreen';

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode;
};

// ----------------------------------------------------------------------

export default function DomainDetailsEditRecordPageGuard({ children }: Props) {
  const { name, recordId } = useParams();

  // API
  const { data: domainRecords, isFetching: isDomainRecordsFetching } = useGetDnsRecordsQuery(name);

  // VAR
  const recordDetails = getRecordDetails(recordId as string, domainRecords);

  if (isDomainRecordsFetching) {
    return <LoadingScreen />;
  }

  if (!recordDetails) {
    return <Navigate to="/404" />;
  }

  return <>{children}</>;
}
