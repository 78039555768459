// @types
import { ProgressStepStatusEnum } from 'src/@types/layout';
import { BackupStep, MigrationStep, MigrationWPCredentialsStep } from 'src/@types/site';

// ----------------------------------------------------------------------

export default function getProgressStatus(
  stepToCheck: BackupStep | MigrationStep | MigrationWPCredentialsStep, // Current examine step
  currentStep: BackupStep | MigrationStep | MigrationWPCredentialsStep, // Crrent in progress step
  steps: (BackupStep | MigrationStep | MigrationWPCredentialsStep)[]
): ProgressStepStatusEnum {
  const currentStepIndex = steps.indexOf(currentStep);
  const stepToCheckIndex = steps.indexOf(stepToCheck);

  return currentStepIndex === stepToCheckIndex
    ? ProgressStepStatusEnum.IN_PROGRESS
    : currentStepIndex < stepToCheckIndex
    ? ProgressStepStatusEnum.WAITING
    : ProgressStepStatusEnum.COMPLETED;
}
