import { Ref, SelectHTMLAttributes, forwardRef } from 'react';
//
import Tooltip from '../Tooltip';

// ----------------------------------------------------------------------

type IProps = {
  options: { value: string | number; label: string; disabled?: boolean }[];
  label?: string;
  labelId?: string; // Required for label
  labelOptional?: string;
  labelIcon?: string;
  labelIconTooltip?: string;
  inputErr?: boolean; // Error color apply to input border as well
  errMsg?: string;
  disabled?: boolean;
  readOnly?: boolean;
  condensed?: boolean;
};

type Props = IProps & SelectHTMLAttributes<HTMLSelectElement>;

// ----------------------------------------------------------------------

const Select = forwardRef(
  (
    {
      options,
      label,
      labelId,
      labelOptional,
      labelIcon,
      labelIconTooltip,
      inputErr,
      errMsg,
      disabled,
      readOnly,
      condensed,
      ...other
    }: Props,
    ref: Ref<HTMLSelectElement>
  ) => (
    <div className="gv-activated">
      <div className="gv-form-option">
        {label && labelId && (
          <label htmlFor={labelId} className={`gv-label${labelIcon ? ' gv-label-with-icon' : ''}`}>
            <span>{label}</span>
            {labelOptional && <span className="gv-label-optional">{labelOptional}</span>}
            {labelIcon &&
              (labelIconTooltip ? (
                <Tooltip tooltip={labelIconTooltip} width={300}>
                  <gv-icon src="/src/icons/info.svg" size="small" />
                </Tooltip>
              ) : (
                <gv-icon src={`/src/icons/${labelIcon}.svg`} />
              ))}
          </label>
        )}

        <div
          className={`gv-input gv-input-select${disabled ? ' gv-disabled' : ''}${
            readOnly ? ' gv-read-only' : ''
          }${condensed ? ' gv-mode-condensed' : ''}${inputErr ? ' gv-error' : ''}`}
        >
          <select ref={ref} id={labelId ? labelId : undefined} className="gv-select" {...other}>
            {options.map((option, index) => (
              <option key={index} value={option.value} disabled={option.disabled}>
                {option.label}
              </option>
            ))}
          </select>
          <gv-icon src="/src/icons/expand_more.svg" />
        </div>

        {errMsg && <span className="gv-input-message gv-error">{errMsg}</span>}
      </div>
    </div>
  )
);

export default Select;
