import { useEffect, useRef, useState, RefObject, ReactNode } from 'react';
// redux
import { useDispatch } from 'src/redux/store';
import { toggleScrollLock } from 'src/redux/features/layout';
// hooks
import useClickOutsideEffect from 'src/hooks/useClickOutside';
import useResponsive from 'src/hooks/useResponsive';

// ----------------------------------------------------------------------

type MenuItem = {
  isIndicated?: boolean;
  isActive?: boolean;
  icon: string;
  text: string;
  function: () => void;
};

type MenuSection = {
  items: MenuItem[];
};

type Props = {
  children: ReactNode;
  sections: MenuSection[];
};

// ----------------------------------------------------------------------

export default function ContextualMenuContainer({ children, sections }: Props) {
  const dispatch = useDispatch();

  const iconRef = useRef<HTMLDivElement>(null);

  // HOOK
  const isMobile = useResponsive('down', 'tablet_min');

  // STATE
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  // EVENT FUNCTION
  function handleOpenMenu() {
    setIsMenuOpen(true);
    dispatch(toggleScrollLock(true));
  }

  function handleCloseMenu() {
    setIsMenuOpen(false);
    dispatch(toggleScrollLock(false));
  }

  //
  // Handle contextual menu layer bug on iphone devices
  useEffect(() => {
    if (!isMobile) return;

    const dataTableScrollHandlerElements = Array.from(
      document.getElementsByClassName('data-table-scroll-handler') as HTMLCollectionOf<HTMLElement>
    );

    if (isMenuOpen) {
      for (let i = 0; i < dataTableScrollHandlerElements.length; i++) {
        dataTableScrollHandlerElements[i].style.overflowX = 'hidden';
      }
    } else {
      for (let i = 0; i < dataTableScrollHandlerElements.length; i++) {
        dataTableScrollHandlerElements[i].style.overflowX = 'auto';
      }
    }
  }, [isMenuOpen, isMobile]);

  useEffect(
    () => () => {
      dispatch(toggleScrollLock(false));
    },
    [dispatch]
  );

  return (
    <div>
      <div
        ref={iconRef}
        onClick={isMenuOpen ? handleCloseMenu : handleOpenMenu}
        style={{ cursor: 'pointer' }}
      >
        {children}
      </div>

      <ContextualMenu
        actionRef={iconRef}
        sections={sections}
        open={isMenuOpen}
        onClose={handleCloseMenu}
      />
    </div>
  );
}

// ----------------------------------------------------------------------

type MenuPosition =
  | 'top-left'
  | 'top-center'
  | 'top-right'
  | 'bottom-left'
  | 'bottom-center'
  | 'bottom-right';

type ContextualMenuProps = {
  actionRef: RefObject<HTMLDivElement>; // Ref object for the element triggering the contextual menu (e.g. the icon element, the button element, etc.)
  open: boolean;
  onClose: () => void;
  sections: MenuSection[];
  position?: MenuPosition;
};

// ----------------------------------------------------------------------

function ContextualMenu({ actionRef, position, sections, open, onClose }: ContextualMenuProps) {
  const dispatch = useDispatch();

  // STATE
  const [menuPosition, setMenuPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });

  // VAR
  const menuRef = useRef<HTMLDivElement>(null);

  // HOOKS
  const isDown600 = useResponsive('down', 600);

  useClickOutsideEffect({
    outsideRefs: [actionRef, menuRef],
    handleClickingOutside: onClose,
    shouldListen: open,
  });

  //
  useEffect(() => {
    // Timeout for debouncing resize events
    let resizeTimeout: ReturnType<typeof setTimeout>;

    const updateMenuPosition = () => {
      // Ensure both iconRef and menuRef are available
      if (!actionRef.current || !menuRef.current) return;

      const iconRect = actionRef.current.getBoundingClientRect();
      const dropdownRect = menuRef.current.getBoundingClientRect();

      let menuTopPos: number = 0;
      let menuLeftPos: number = 0;
      const scrollYPos = window.scrollY;

      switch (position) {
        case 'top-left':
          menuTopPos = iconRect.top - dropdownRect.height;
          menuLeftPos = iconRect.left;
          break;
        case 'top-center':
          menuTopPos = iconRect.top - dropdownRect.height;
          menuLeftPos = iconRect.left - dropdownRect.width / 2 + iconRect.width / 2;
          break;
        case 'top-right':
          menuTopPos = iconRect.top - dropdownRect.height;
          menuLeftPos = iconRect.right - dropdownRect.width;
          break;
        case 'bottom-left':
          menuTopPos = iconRect.bottom;
          menuLeftPos = iconRect.left;
          break;
        case 'bottom-center':
          menuTopPos = iconRect.bottom;
          menuLeftPos = iconRect.left - dropdownRect.width / 2 + iconRect.width / 2;
          break;
        case 'bottom-right':
          menuTopPos = iconRect.bottom;
          menuLeftPos = iconRect.right - dropdownRect.width;
          break;

        default:
          // Default to bottom-right
          menuTopPos = iconRect.bottom;
          menuLeftPos = iconRect.right - dropdownRect.width;
      }
      setMenuPosition({ top: menuTopPos + scrollYPos, left: menuLeftPos });
    };

    // Function to handle resize events with debounce
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(updateMenuPosition, 200);
    };

    // Initial update of the menu position
    updateMenuPosition();

    // Add event listener for resize events
    window.addEventListener('resize', handleResize);

    // Cleanup: remove event listeners and reset body styles
    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, open]);

  //
  useEffect(
    () => () => {
      onClose(); // Cannot be inside deps array since it will force this to rerun everytime component is rerender
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return open ? (
    <div
      className="gv-contextual-menu"
      style={{
        top: isDown600 ? 0 : `${menuPosition.top}px`,
        left: isDown600 ? 0 : `${menuPosition.left}px`,
      }}
    >
      <div ref={menuRef} className="gv-menu">
        {/* The menuRef is attached to the gv-menu element because we need make the handleClickOutside function work when screen is less than 600px*/}
        {sections.map((section, sectionIndex) => (
          <div key={sectionIndex}>
            <ul>
              {section.items.map((item, itemIndex) => (
                <li
                  key={itemIndex}
                  onClick={() => {
                    item.function();
                    onClose();
                  }}
                >
                  <button className={`gv-menu-item ${item.isActive ? ' gv-active' : ''}`}>
                    <gv-icon src={`/src/icons/${item.icon}.svg`} size="small" />
                    <span>{item.text}</span>
                    {item.isIndicated && <div className="gv-indicator" />}
                  </button>
                </li>
              ))}
            </ul>
            {/* Add divider after each section except for the last one */}
            {sectionIndex !== sections.length - 1 && (
              <div className="gv-divider gv-divider-light" />
            )}
          </div>
        ))}
      </div>
    </div>
  ) : (
    <></>
  );
}
