// @mui
import { styled } from '@mui/material/styles';
import { Box, Card, Stack, Divider, Typography } from '@mui/material';

// ----------------------------------------------------------------------

const HeaderStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(4, 5, 0, 5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& div': {
      marginBottom: theme.spacing(2),
    },
  },
}));

// ----------------------------------------------------------------------

type Props = {
  title: string;
  actions?: React.ReactNode;
  content: React.ReactNode;
};

// ----------------------------------------------------------------------

export default function Block({ title, actions, content }: Props) {
  return (
    <Card>
      <HeaderStyle>
        <Stack
          flexGrow={1}
          spacing={2}
          direction={{ xs: 'row', sm: 'row' }}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Typography variant="h4" align="center">
            {title}
          </Typography>
        </Stack>

        {actions && actions}
      </HeaderStyle>
      <Box sx={{ py: 4, px: 5 }}>{content}</Box>
      <Divider />
    </Card>
  );
}
