import { useParams } from 'react-router';
// redux
import { useSelector } from 'src/redux/store';
// @types
import { CloudflareCreateResponse } from '@joonasvanhatapio/wp-cloud-backend-types';
import { StateTypeEnum } from 'src/@types/domain';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { isHostnetCustomer } from 'src/utils/check';
import { displayToast } from 'src/utils/handleToast';
// components
import { PageSection } from 'src/components/gravity/page';
import IconWrapper from 'src/components/gravity/IconWrapper';

// ----------------------------------------------------------------------

type Props = {
  domain: CloudflareCreateResponse;
};

// ----------------------------------------------------------------------

export default function NameServersBlock({ domain }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  // STATE
  const { user } = useSelector((state) => state.auth);

  return (
    <PageSection title={translate('wpone.domains.details.settings.nameServers.title')}>
      <div className="gv-content-container gv-w-full">
        <div className="gv-flex-column-md gv-p-lg">
          <p className="gv-text-sm">
            {translate(
              `wpone.domains.details.settings.nameServers.description.${
                domain.state.type === StateTypeEnum.error ? 'notManaged' : 'managed'
              }`
            )}
            {domain.state.type === StateTypeEnum.error && isHostnetCustomer(user) && (
              <>
                <br />
                {translate('wpone.domains.details.settings.nameServers.description.hostnetNote')}
              </>
            )}
          </p>
          <div className="gv-flex-row-lg gv-justify-between">
            {domain.ns.map((nameserver, index) => (
              <NameServerInfo key={index} index={index} value={nameserver} />
            ))}
          </div>
        </div>
        <div
          className={`gv-notice gv-notice-addon gv-notice-${
            domain.state.type === StateTypeEnum.error ? 'alert' : domain.state.type
          }`}
        >
          <gv-icon
            class="gv-notice-icon"
            src={`/src/icons/${
              domain.state.type === StateTypeEnum.success ? 'check_circle' : domain.state.type
            }.svg`}
          />
          <p className="gv-notice-content">
            {translate(`wpone.domains.details.settings.nameServers.notice.${domain.state.type}`, {
              domainName: name,
            })}
          </p>
        </div>
      </div>
    </PageSection>
  );
}

// ----------------------------------------------------------------------

type NameServerInfoProps = {
  index: number;
  value: string;
};

// ----------------------------------------------------------------------

function NameServerInfo({ index, value }: NameServerInfoProps) {
  // HOOK
  const { translate } = useLocales();

  return (
    <div className="gv-flex gv-flex-col gv-flex-1">
      <p className="gv-text-sm gv-text-bold" style={{ color: 'var(--color-body-on-placeholder)' }}>
        {translate('wpone.domains.details.settings.nameServers.label', {
          orderNumber: index + 1,
        })}
      </p>
      <div className="gv-flex-row-sm gv-items-center">
        <p className="gv-text-sm">{translate(value)}</p>
        <IconWrapper
          onClick={() => {
            navigator.clipboard.writeText(value);
            displayToast(translate('wpone.general.action.copied'));
          }}
          style={{ cursor: 'pointer' }}
        >
          <gv-icon src="/src/icons/content_copy.svg" />
        </IconWrapper>
      </div>
    </div>
  );
}
