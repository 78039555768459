import { useState } from 'react';
import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// @types
import { DnsRecordType, DnsRecordRowProps } from 'src/@types/domain';
// hooks
import useLocales from 'src/hooks/useLocales';
import useResponsive from 'src/hooks/useResponsive';
// utils
import { convertToIDN } from 'src/utils/convert';
import { streamingDownload } from 'src/utils/download';
import { displayToast } from 'src/utils/handleToast';
// config
import { HOST_API } from 'src/config';
// components
import ContextualMenuContainer from 'src/components/gravity/ContextualMenuContainer';
import { Select } from 'src/components/gravity/form';

// ----------------------------------------------------------------------

type Props = {
  tableData: DnsRecordRowProps[];
  handleToolbarFilterType: (type: DnsRecordType | 'all') => void;
  handleToolbarDeleteRecord: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DnsRecordTableToolbar({
  tableData,
  handleToolbarFilterType,
  handleToolbarDeleteRecord,
}: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMobile = useResponsive('down', 'tablet_min');

  // STATE
  const [open, setOpen] = useState<boolean>(false);

  // EVENT FUNCTION
  function handleExportDnsRecords() {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'export-dns-records',
    });

    const url = `/api/v5/zones/${convertToIDN(name)}/dns/export`;
    const rootUrl = HOST_API || new URL(window.location.href).origin;

    streamingDownload(`${rootUrl}${url}`, 'dns-export.txt');

    displayToast(
      translate('wpone.domains.details.dnsRecords.table.toast.startExportingDNSRecords'),
      {
        variant: 'info',
      }
    );
  }

  return (
    <div className="gv-content-container gv-flex-column-sm gv-tab-flex-row gv-tab-justify-between gv-tab-items-center gv-p-sm">
      <div
        style={{
          width: '100%',
          maxWidth: isMobile ? '100%' : '296px',
        }}
      >
        <Select
          options={['all', ...Object.values(DnsRecordType)].map((type) => ({
            value: type,
            label:
              type === 'all'
                ? translate('wpone.domains.details.dnsRecords.table.toolbar.allTypesFilter')
                : type,
          }))}
          condensed
          onChange={(e) => handleToolbarFilterType(e.target.value as DnsRecordType | 'all')}
        />
      </div>

      <ContextualMenuContainer
        sections={[
          {
            items: [
              {
                icon: 'download',
                text: translate('wpone.domains.details.dnsRecords.table.toolbar.action.export'),
                function: handleExportDnsRecords,
              },
              {
                icon: 'delete',
                text: translate('wpone.domains.details.dnsRecords.table.toolbar.action.delete'),
                function: handleToolbarDeleteRecord,
              },
            ].filter((action) => {
              if (tableData.filter((row) => row.selected).length === 0)
                return action.icon !== 'delete';
              return true;
            }),
          },
        ]}
      >
        <a
          href="/#"
          className="gv-action gv-action-primary"
          onClick={(e) => {
            e.preventDefault();
            setOpen((prev) => !prev);
          }}
        >
          <span className="gv-text-sm gv-text-bold">
            {translate('wpone.domains.details.dnsRecords.table.toolbar.action.label')}
          </span>
          <gv-icon src={`/src/icons/keyboard_arrow_${open ? 'up' : 'down'}.svg`} />
        </a>
      </ContextualMenuContainer>
    </div>
  );
}
