//
import ContextualMenuContainer from '../ContextualMenuContainer';
import IconWrapper from '../IconWrapper';

// ----------------------------------------------------------------------

type MenuItem = {
  isIndicated?: boolean;
  isActive?: boolean;
  icon: string;
  text: string;
  function: () => void;
};

type MenuSection = {
  items: MenuItem[];
};

type Props = {
  sections: MenuSection[];
};

// ----------------------------------------------------------------------

export default function TableMoreMenu({ sections }: Props) {
  return (
    <ContextualMenuContainer sections={sections}>
      <IconWrapper style={{ cursor: 'pointer' }}>
        <gv-icon src="/src/icons/more_horiz.svg" />
      </IconWrapper>
    </ContextualMenuContainer>
  );
}
