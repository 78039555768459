import { PayloadAction, createSlice } from '@reduxjs/toolkit';
// @types
import { DomainState } from 'src/@types/domain';

// ----------------------------------------------------------------------

const initialState: DomainState = {
  // Displaying added record banner
  recordAddedDomains: [],
  // Displaying edited record banner
  recordEditedDomains: [],
};

const slice = createSlice({
  name: 'domain',
  initialState,
  reducers: {
    // ADD DOMAIN WITH NEW RECORD ADDED
    addRecordAddedDomain(state, action: PayloadAction<string>) {
      state.recordAddedDomains = [...state.recordAddedDomains, action.payload];
    },
    // REMOVE DOMAIN WITH NEW RECORD ADDED
    removeRecordAddedDomain(state, action: PayloadAction<string>) {
      state.recordAddedDomains = state.recordAddedDomains.filter(
        (domain) => domain !== action.payload
      );
    },
    // ADD DOMAIN WITH NEW RECORD EDITED
    addRecordEditedDomain(state, action: PayloadAction<string>) {
      state.recordEditedDomains = [...state.recordEditedDomains, action.payload];
    },
    // REMOVE DOMAIN WITH NEW RECORD EDITED
    removeRecordEditedDomain(state, action: PayloadAction<string>) {
      state.recordEditedDomains = state.recordEditedDomains.filter(
        (domain) => domain !== action.payload
      );
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addRecordAddedDomain,
  removeRecordAddedDomain,
  addRecordEditedDomain,
  removeRecordEditedDomain,
} = slice.actions;
