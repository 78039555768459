import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { useDeleteDnsRecordMutation } from 'src/redux/api/domainApi';
// @types
import { DeletingRecordsState } from 'src/@types/domain';
import { TableHeader } from 'src/@types/layout';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
import { fDnsRecordFullName } from 'src/utils/format';
// components
import { TableHeadCustom } from 'src/components/gravity/table';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import AlertBanner from 'src/components/AlertBanner';

// ----------------------------------------------------------------------

const TABLE_HEAD: TableHeader[] = [
  {
    id: 'type',
    label: 'wpone.domains.details.dnsRecords.deleteDnsRecordDialog.table.header.type',
  },
  {
    id: 'name',
    label: 'wpone.domains.details.dnsRecords.deleteDnsRecordDialog.table.header.name',
  },
  {
    id: 'content',
    label: 'wpone.domains.details.dnsRecords.deleteDnsRecordDialog.table.header.content',
  },
];
// ----------------------------------------------------------------------

type Props = {
  isBunnyDns: boolean;
  deletingRecordsState: DeletingRecordsState;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function DeleteDnsRecordConfirmation({
  isBunnyDns,
  deletingRecordsState,
  onClose,
}: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  // API
  const [deleteDnsRecord, { isLoading: deleteIsLoading }] = useDeleteDnsRecordMutation();

  // VAR
  const { records, allSelected } = deletingRecordsState;

  // EVENT FUNCTION
  const onDeleteDnsRecordHandler = () => {
    if (!name) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: `delete-dns-record`,
    });

    deleteDnsRecord({
      domain: name,
      ids: records.map((record) => record.id.toString()),
    })
      .unwrap()
      .then((data) => {
        if (data) {
          const successDeletionsNumber = data.filter(
            (deletion) => deletion.success === true
          ).length;
          const failedDeletionMessages = data
            .filter((deletion) => deletion.success === false)
            .map((deletion) => deletion.message);

          displayToast(
            translate(
              getToastMessage(data.length, successDeletionsNumber, failedDeletionMessages),
              {
                success_number: successDeletionsNumber,
                failed_number: failedDeletionMessages.length,
                total_number: data.length,
              }
            ),
            { variant: successDeletionsNumber === data.length ? 'success' : 'alert' }
          );
        } else {
          displayToast(translate('wpone.general.requestResponse.errorMessage.other'), {
            variant: 'alert',
          });
        }
        onClose();
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        onClose();
      });
  };

  // HELPER FUNCTION
  function getToastMessage(total: number, totalSuccess: number, failedMessages: string[]) {
    if (total === 1) {
      return totalSuccess === total
        ? 'wpone.domains.details.dnsRecords.deleteDnsRecordDialog.requestResponse.single.success'
        : `wpone.domains.details.dnsRecords.deleteDnsRecordDialog.requestResponse.single.failed.${
            !includesContactSupportFailure(failedMessages) ? 'tryAgain' : 'contactSupport'
          }`;
    } else {
      return totalSuccess === total
        ? 'wpone.domains.details.dnsRecords.deleteDnsRecordDialog.requestResponse.multiple.success'
        : `wpone.domains.details.dnsRecords.deleteDnsRecordDialog.requestResponse.multiple.failed.${
            totalSuccess === 0 ? 'all' : 'partial'
          }.${!includesContactSupportFailure(failedMessages) ? 'tryAgain' : 'contactSupport'}`;
    }
  }

  function includesContactSupportFailure(msgs: string[]) {
    if (
      msgs.includes('Interval server error on BunnyCDN') ||
      msgs.includes('Error in deleteBunnyDNSRecord: the request DNS zone was not found')
    ) {
      return true;
    }
    return false;
  }

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.domains.details.dnsRecords.deleteDnsRecordDialog.title')}
        </h2>

        <div className="gv-flex-column-sm" style={{ overflowX: 'auto' }}>
          <p>
            {translate(
              `wpone.domains.details.dnsRecords.deleteDnsRecordDialog.description.${
                records.length > 1 ? 'multiple' : 'singular'
              }`
            )}
          </p>

          {allSelected && (
            <AlertBanner severity={'error'}>
              {translate('wpone.domains.details.dnsRecords.deleteDnsRecordDialog.warningBanner')}
            </AlertBanner>
          )}

          <div className="gv-data-table" style={{ overflowX: 'auto' }}>
            <table className="gv-col-1-shrink">
              <TableHeadCustom headers={TABLE_HEAD} />
              <tbody>
                {records.map((record) => (
                  <tr key={record.id}>
                    <td>{record.type}</td>
                    <td>{fDnsRecordFullName(record.name, name, isBunnyDns)}</td>
                    <td>
                      <p>{record.content}</p>
                      {record.priority !== undefined && (
                        <p className="gv-caption-sm gv-text-on-alternative">
                          {translate(
                            'wpone.domains.details.dnsRecords.deleteDnsRecordDialog.table.row.content',
                            {
                              priority: record.priority,
                            }
                          )}
                        </p>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.general.action.delete')}
          uiType="destructive"
          onClick={onDeleteDnsRecordHandler}
          disabled={deleteIsLoading}
        />
      </ButtonGroup>
    </>
  );
}
