import { format } from 'date-fns';
import numeral from 'numeral';
// @types
import { LocaleEnum } from 'src/@types/user';

// ----------------------------------------------------------------------

// FORMAT Display
export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}

export function fCapitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

// DNS record name display
export function fDnsRecordFullName(
  recordName: string | undefined,
  zoneName: string | undefined,
  isBunnyDns: boolean
) {
  // Bunny domain recordName will be received in the format ${subdomain}
  // Cloudflare domain recordName will be received in the format ${subdomain}.${domain}
  // Record name display will be in full ${subdomain}.${domain} only in delete record modal

  if (!recordName) {
    return zoneName || '';
  }

  if (!isBunnyDns) {
    return recordName;
  }

  return `${recordName}.${zoneName}`;
}

export function fDnsRecordSubname(
  recordName: string | undefined,
  zoneName: string | undefined,
  isBunnyDns: boolean
) {
  // Bunny domain recordName will be received in the format ${subdomain}
  // Cloudflare domain recordName will be received in the format ${subdomain}.${domain}
  // Record name display will be in sub ${subdomain} in edit record page and record table
  // Note: in record table, if sub is empty, zoneName will be used (the logic for this is added in DnsRecordTableRow)
  if (!recordName) {
    return '';
  }

  if (!isBunnyDns) {
    const lastIndex = recordName.lastIndexOf(`.${zoneName}`);
    if (lastIndex !== -1) {
      return recordName.substring(0, lastIndex);
    }
  }

  return recordName;
}

// ----------------------------------------------------------------------

// FORMAT TIME
export function fShortDate(date: Date | string | number, locale?: string) {
  const convertedFormat = getLocaleFormat(locale);

  const formattedDate = format(new Date(date), convertedFormat.shortDate);

  // Example
  // 08/10/2024
  return formattedDate;
}

export function fShortDateTime(
  date: Date | string | number,
  optional?: {
    locale?: string;
    separatedByComma?: boolean;
    secondIncluded?: boolean;
  }
) {
  const convertedFormat = getLocaleFormat(optional?.locale);

  const shortDateTimeFormat = convertedFormat.shortDate.concat(
    optional?.separatedByComma ? ', ' : ' ',
    optional?.secondIncluded ? convertedFormat.timeWithSec : convertedFormat.time
  );

  const formattedDate = format(new Date(date), shortDateTimeFormat);

  // Example
  // 08/10/2024 15:04
  // 08/10/2024, 15:04 - separatedByComma
  // 08/10/2024 15:04:37 - secondIncluded
  // 08/10/2024, 15:04:37 - separatedByComma & secondIncluded
  return formattedDate;
}

export function formatDateByMonth(dateString: string, locale: string): string {
  // dateString is in the format "MM/YYYY"
  const dateParts = dateString.split('/');
  const year = dateParts[dateParts.length === 1 ? 0 : 1];
  const month = dateParts[dateParts.length === 1 ? 1 : 0].padStart(2, '0');

  let separator: string = '/';
  if (locale === 'fi') {
    separator = '.';
  } else if (locale === 'nl') {
    separator = '-';
  }

  return `${month}${separator}${year}`;
}

function getLocaleFormat(locale?: string) {
  switch (locale) {
    case LocaleEnum.en:
      return {
        shortDate: 'dd/MM/yyyy',
        time: 'HH:mm',
        timeWithSec: 'HH:mm:ss',
      };
    case LocaleEnum.fi:
      return {
        shortDate: 'dd.MM.yyyy',
        time: 'HH.mm',
        timeWithSec: 'HH.mm.ss',
      };
    case LocaleEnum.nl:
      return {
        shortDate: 'dd-MM-yyyy',
        time: 'HH:mm',
        timeWithSec: 'HH:mm:ss',
      };
    case LocaleEnum.no:
      return {
        shortDate: 'dd-MM-yyyy',
        time: 'HH:mm',
        timeWithSec: 'HH:mm:ss',
      };
    default:
      return {
        shortDate: 'dd/MM/yyyy',
        time: 'HH:mm',
        timeWithSec: 'HH:mm:ss',
      };
  }
}

// ----------------------------------------------------------------------

// FORMAT LONG NAME
export function fLongName(name: string) {
  return name.length > 30 ? `${name.slice(0, 10)}...${name.slice(-18)}` : name;
}

// ----------------------------------------------------------------------

// FORMAT TRANSLATION KEY
// Only use when meesage returned from API having the space in it. Example: "User already exists" => "userAlreadyExist"
// Format arg is used when there is a dynamic text in the message. Example: "User demo@gmail.com already exists" => "userAlreadyExists" with passed in format: "User * already exists"
// When message is already in the no-space + static format => no need to use this function. Example: "user_already_exists"
export function fTranslationDynamicKey(errorMsg: string, format?: string) {
  if (!errorMsg) return;

  let result = '';

  if (format) {
    const formatParts = format.split('*');
    let lastIndex = 0;

    for (let i = 0; i < formatParts.length; i++) {
      const part = formatParts[i];
      const index = errorMsg.indexOf(part, lastIndex);
      if (index !== -1) {
        result += part;
        lastIndex = index + part.length;
      } else {
        break;
      }
    }
  }

  // If there is a format but the message is not matched => return
  if (format && result === '') return;

  // Remove special characters and convert to camel case
  let words = (format ? result : errorMsg)
    .toLowerCase()
    .replace(/[^a-zA-Z0-9\s]/g, '')
    .split(' ')
    .filter((word) => word !== '');

  let key = words[0].toLowerCase();

  for (let i = 1; i < words.length; i++) {
    key += words[i].charAt(0).toUpperCase() + words[i].slice(1).toLowerCase();
  }

  return key;
}

// FORMAT URL
export function fHttpPrefix(url: string) {
  // Remove both "https://" and "http://" prefixes
  url = url.replace(/^https?:\/\//, '');

  if (url.endsWith('/')) {
    url = url.slice(0, -1);
  }

  return url;
}
