import { useParams } from 'react-router';
import ReactGA from 'react-ga4';
// redux
import { dispatch, useSelector } from 'src/redux/store';
import { userApi } from 'src/redux/api/userApi';
import { useTrasferZoneOwnershipMutation } from 'src/redux/api/resourceApi';
import { AccessPermissionFullExpandedDTO } from '@joonasvanhatapio/wp-cloud-backend-types';
// form
import { useForm } from 'react-hook-form';
// @types
import { UserRoleEnum } from 'src/@types/user';
// hooks
import useLocales from 'src/hooks/useLocales';
// utils
import { convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFCheckbox } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import AlertBanner from 'src/components/AlertBanner';

// ----------------------------------------------------------------------

type FormValuesProps = {
  removeCurrentOwner: boolean;
  afterSubmit?: string;
};

type Props = {
  onClose: VoidFunction;
  accessPermissionInfo: AccessPermissionFullExpandedDTO | null;
  ownerAccessPermissionInfo: AccessPermissionFullExpandedDTO | null;
};

// ----------------------------------------------------------------------

export default function TransferOwnershipForm({
  accessPermissionInfo,
  ownerAccessPermissionInfo,
  onClose,
}: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  // API
  const [transferZoneOwnership, { isLoading }] = useTrasferZoneOwnershipMutation();

  // FORM
  const defaultValues = {
    removeCurrentOwner: false,
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  // STATE
  const { user } = useSelector((state) => state.auth);

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (ownerAccessPermissionInfo === null) {
      displayToast(translate('wpone.general.requestResponse.errorMessage.other'), {
        variant: 'alert',
      });
      return;
    }

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'transfer-zone-ownership',
    });

    transferZoneOwnership({
      zoneName: name,
      transferData: {
        recipient: accessPermissionInfo?.user.email as string,
        remove_current: data.removeCurrentOwner,
      },
    })
      .unwrap()
      .then(() => {
        displayToast(
          translate(
            'wpone.domains.details.users.transferOnwershipDialog.requestResponse.successMessage'
          )
        );
        // For getting latest update on Customer table (for support/service)
        dispatch(userApi.util.invalidateTags([{ type: 'User', id: 'PARTIAL-LIST' }]));
        onClose();
      })
      .catch((error) => {
        const errCode = (error as { status: number; data: any }).status;
        displayToast(translate(convertStatusCode(errCode)), { variant: 'alert' });
        onClose();
      });
  };

  return (
    <>
      <div className="gv-modal-body">
        <h2 className="gv-modal-title">
          {translate('wpone.domains.details.users.transferOnwershipDialog.title', {
            email: accessPermissionInfo?.user.email,
          })}
        </h2>

        <FormProvider methods={methods}>
          <div className="gv-flex-column-sm">
            <p>
              {translate('wpone.domains.details.users.transferOnwershipDialog.description', {
                domain: name,
                email: accessPermissionInfo?.user.email,
              })}
            </p>

            {(user?.role === UserRoleEnum.support ||
              user?.role === UserRoleEnum.serviceAccount) && (
              <RHFCheckbox
                name="removeCurrentOwner"
                labelId="remove-current-owner"
                label={translate(
                  'wponesupport.domains.details.users.transferOnwershipDialog.removeCurrentOwnerAccess'
                )}
                condensed
              />
            )}

            <AlertBanner severity="warning">
              {translate('wpone.domains.details.users.transferOnwershipDialog.warning')}
            </AlertBanner>
          </div>
        </FormProvider>
      </div>

      {/* Dialog header and footer style are set globally */}
      <ButtonGroup>
        <Button text={translate('wpone.general.action.cancel')} uiType="cancel" onClick={onClose} />
        <Button
          text={translate('wpone.domains.details.users.transferOnwershipDialog.action')}
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading}
        />
      </ButtonGroup>
    </>
  );
}
