import { useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router';
import { FileRejection } from 'react-dropzone';
import ReactGA from 'react-ga4';
// redux
import { useAddDnsRecordMutation } from 'src/redux/api/domainApi';
// form
import { useForm } from 'react-hook-form';
// @mui
import { Collapse } from '@mui/material';
// @types
import {
  DnsRecordType,
  FormattedCreateDNSRequestBodyDTO,
  ImportRecordType,
} from 'src/@types/domain';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { convertNumberToTtl, convertStatusCode } from 'src/utils/convert';
import { displayToast } from 'src/utils/handleToast';
// components
import { FormProvider, RHFUploadSingleFile } from 'src/components/gravity/hook-form';
import Button from 'src/components/gravity/Button';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import AlertBanner from 'src/components/AlertBanner';
import Loader from 'src/components/gravity/Loader';
import IconWrapper from 'src/components/gravity/IconWrapper';

// ----------------------------------------------------------------------

interface CustomFile extends File {
  path?: string;
  preview?: string;
  lastModifiedDate?: Date;
}

export type FormValuesProps = {
  recordFile: CustomFile | string | null;
  afterSubmit?: string;
};

type Props = {
  isBunnyDns: boolean;
  onClose: VoidFunction;
};

// ----------------------------------------------------------------------

export default function ImportRecordsFileForm({ isBunnyDns, onClose }: Props) {
  const { name } = useParams();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [addDnsRecord] = useAddDnsRecordMutation();

  // STATE
  // Keeping track of all records need to be imported, importing state, and showing error state (result panel)
  const [recordArr, setRecordArr] = useState<ImportRecordType[]>([]);
  const [isImporting, setIsImporting] = useState(false);
  const [isShowError, setIsShowError] = useState(false);

  // Keeping track of rejection file after checking number of dns records detected (extra rejection for uploading)
  const [isRejected, setIsRejected] = useState(false);

  // Keeping track of number of imported records (both successfully and failed)
  const [importedProgress, setImportedProgress] = useState<{ total: number; done: number }>({
    total: 0,
    done: 0,
  });
  const latestImportedProgress = useRef(importedProgress);

  // Keeping track of failed imported records (the failed record object, not only number)
  const [failedImportedRecords, setFailedImportedRecords] = useState<
    {
      message: string;
      statusCode: number;
      fileContent: string;
    }[]
  >([]);

  // VAR
  const latestFailedImportedRecords = useRef(failedImportedRecords);

  // FORM
  const defaultValues = {
    recordFile: null,
  };

  const methods = useForm<FormValuesProps>({
    defaultValues,
  });

  const {
    setValue,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit = async () => {
    ReactGA.event({
      category: 'button',
      action: 'click',
      label: 'import-record-file',
    });

    setImportedProgress((prev) => {
      latestImportedProgress.current = { ...prev, total: recordArr.length };
      return { ...prev, total: recordArr.length };
    });

    setIsImporting(true);

    for (const record of recordArr) {
      let newRecord = {
        name: record.name,
        type: record.type,
        content: record.content,
        ttl: record.ttl,
        proxied: false,
      } as FormattedCreateDNSRequestBodyDTO;

      if (record.priority !== undefined) {
        newRecord = {
          ...newRecord,
          priority: record.priority,
        } as FormattedCreateDNSRequestBodyDTO;
      }

      if (record.weight !== undefined) {
        newRecord = {
          ...newRecord,
          content: `${record.weight}\t${record.port}\t${record.content}`,
          priority: record.priority,
        } as FormattedCreateDNSRequestBodyDTO;
      }

      if (isMountedRef.current) {
        await addDnsRecord({
          domainName: name,
          newDnsRecord: newRecord,
          isBunnyDns: isBunnyDns,
        })
          .unwrap()
          .then(() => {
            if (!isMountedRef.current) {
              // Actual current done here will be latestImportedProgress.current.done + 1 because it is unmounted so the real data will not be updated
              displayToast(
                translate(
                  'wpone.domains.details.dnsRecords.importDnsRecordsDialog.requestResponse.errorMessage.multipleRecords',
                  {
                    importedRecords:
                      1 +
                      latestImportedProgress.current.done -
                      latestFailedImportedRecords.current.length,
                    total: latestImportedProgress.current.total,
                  }
                ),
                { variant: 'alert' }
              );
            }
          })
          .catch((rejected) => {
            if (isMountedRef.current) {
              // Only update failed imports when it is still mounted
              setFailedImportedRecords((prev) => {
                latestFailedImportedRecords.current = [
                  ...prev,
                  {
                    message: rejected?.data?.message || convertStatusCode(rejected.status),
                    statusCode: rejected.data.statusCode || rejected.status,
                    fileContent: record.fileContent,
                  },
                ];

                return [
                  ...prev,
                  {
                    message: rejected?.data?.message || convertStatusCode(rejected.status),
                    statusCode: rejected.data.statusCode || rejected.status,
                    fileContent: record.fileContent,
                  },
                ];
              });
            } else {
              // Actual current done here will be latestImportedProgress.current.done + 1 because it is unmounted, so the real data will not be updated
              // Actual failed imports here will be latestFailedImportedRecords.current.length + 1 because it is unmounted, so the real data will not be udpated
              // Both +1 so it still equals latestImportedProgress.current.done - latestFailedImportedRecords.current.length
              displayToast(
                translate(
                  'wpone.domains.details.dnsRecords.importDnsRecordsDialog.requestResponse.errorMessage.multipleRecords',
                  {
                    importedRecords:
                      latestImportedProgress.current.done -
                      latestFailedImportedRecords.current.length,
                    total: latestImportedProgress.current.total,
                  }
                ),
                { variant: 'alert' }
              );
            }
          })
          .finally(() => {
            if (isMountedRef.current) {
              // Only update "done" state when it is still mounted
              setImportedProgress((prev) => {
                latestImportedProgress.current = { ...prev, done: prev.done + 1 };
                return { ...prev, done: prev.done + 1 };
              });
            }
          });
      }
    }

    if (isMountedRef.current) {
      setIsImporting(false);
    }
  };

  // EVENT FUNCTION
  const handleDropAccepted = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const fileReader = new FileReader();

      if (file) {
        fileReader.readAsText(file);

        fileReader.onload = function (event) {
          const transferedFileContent = handleTransferFile(event.target?.result as string);

          setValue(
            'recordFile',
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          );

          setRecordArr(transferedFileContent);

          if (transferedFileContent.length === 0) {
            setIsRejected(true);
          }
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setValue]
  );

  const handleDropRejected = useCallback(
    (rejectedFiles: FileRejection[]) => {
      setValue(
        'recordFile',
        Object.assign(rejectedFiles[0].file, {
          preview: URL.createObjectURL(rejectedFiles[0].file),
        })
      );

      setRecordArr([]);
    },
    [setValue]
  );

  const handleClearPrevState = () => {
    setValue('recordFile', null);

    setRecordArr([]);

    setIsRejected(false);
  };

  // HELPER FUNCTION
  function handleTTLConversion(ttlStr: string) {
    const timeUnits: { [key in string]: number } = { m: 60, h: 3600, d: 86400 };
    const unit = ttlStr.slice(-1);
    if (unit in timeUnits) {
      return convertNumberToTtl(Number(ttlStr.slice(0, -1)) * timeUnits[unit]);
    } else if (!isNaN(Number(ttlStr))) {
      return convertNumberToTtl(Number(ttlStr));
    } else {
      return undefined;
    }
  }

  function handleDisplayRecords(recordArr: ImportRecordType[]) {
    const typeArr = Object.values(DnsRecordType);
    // Use the map method to iterate over the array of types,
    // filter the records by each type and returning an object with the type and count.
    const counts: { type: DnsRecordType; number: number }[] = typeArr.map((type) => {
      const records = recordArr.filter((record) => record.type === type);
      return { type, number: records.length };
    });

    // Use the filter method to remove any counts with a value of 0
    return counts.filter((count) => count.number > 0);
  }

  function handleTransferFile(fileContent: string) {
    let lines = fileContent.split(/\r?\n/).filter((line) => line.trim() !== '');
    let resultArr: ImportRecordType[] = [];

    // Record format in files:
    // A, AAAA, CNAME, TXT: {{ name }}(remove if there is a dot) {{ TTL }} IN {{ TYPE }} {{ CONTENT }}(remove if there is a dot)
    // MX: {{ name }}(remove if there is a dot) {{ TTL }} IN {{ TYPE }} {{ PRIORITY }} {{ CONTENT }}(remove if there is a dot)
    // SRV: {{ name }}(remove if there is a dot) {{ TTL }} IN SRV {{ WEIGHT }} {{ PRIORITY }} {{ PORT }} {{ CONTENT }}(remove if there is a dot)

    lines.forEach((line) => {
      const subStrArr = line.trim().split(/\s+/);
      const ttl = handleTTLConversion(subStrArr[1]);
      const name = detectAndRemoveDot(subStrArr[0]);

      if (ttl !== undefined) {
        if (
          subStrArr[3] === DnsRecordType.A ||
          subStrArr[3] === DnsRecordType.AAAA ||
          subStrArr[3] === DnsRecordType.CNAME ||
          subStrArr[3] === DnsRecordType.TXT
        ) {
          resultArr.push({
            fileContent: line,
            name: name,
            ttl: ttl,
            type: subStrArr[3],
            content: detectAndRemoveDot(
              subStrArr[3] === DnsRecordType.TXT ? line.split('"')[1] : subStrArr[4]
            ),
          });
        } else if (subStrArr[3] === DnsRecordType.MX) {
          resultArr.push({
            fileContent: line,
            name: name,
            ttl: ttl,
            type: subStrArr[3],
            priority: Number(subStrArr[4]),
            content: detectAndRemoveDot(subStrArr[5]),
          });
        } else if (subStrArr[3] === DnsRecordType.SRV) {
          resultArr.push({
            fileContent: line,
            name: name,
            ttl: ttl,
            type: subStrArr[3],
            weight: subStrArr[4],
            priority: Number(subStrArr[5]),
            port: subStrArr[6],
            content: detectAndRemoveDot(subStrArr[7]),
          });
        }
      }
    });

    return resultArr;
  }

  function detectAndRemoveDot(str: string) {
    if (str[str.length - 1] === '.') {
      return str.slice(0, str.length - 1);
    }

    return str;
  }

  return (
    <>
      {importedProgress.total !== 0 ? (
        <>
          <div className="gv-modal-body">
            <h2 className="gv-modal-title">
              {translate('wpone.domains.details.dnsRecords.importDnsRecordsDialog.title')}
            </h2>

            {isImporting ? (
              <h3 className="gv-modal-subtitle gv-text-center">
                {translate(
                  'wpone.domains.details.dnsRecords.importDnsRecordsDialog.inProgress.description'
                )}
              </h3>
            ) : (
              <h3 className="gv-modal-subtitle">
                {translate(
                  'wpone.domains.details.dnsRecords.importDnsRecordsDialog.finished.description'
                )}
              </h3>
            )}

            <div
              className={`gv-flex gv-flex-column-lg${
                isImporting ? ' gv-items-center' : ' gv-items-start'
              }`}
              style={{ overflowX: 'auto' }}
            >
              {isImporting ? (
                <>
                  <Loader />
                  <p>
                    {translate(
                      'wpone.domains.details.dnsRecords.importDnsRecordsDialog.inProgress.info',
                      {
                        finishedNumber: importedProgress.done + 1,
                        totalNumber: importedProgress.total,
                      }
                    )}
                  </p>
                  <p className="gv-text-bold">
                    {translate('wpone.domains.details.dnsRecords.importDnsRecordsDialog.warning')}
                  </p>
                </>
              ) : (
                <>
                  <p>
                    {translate(
                      'wpone.domains.details.dnsRecords.importDnsRecordsDialog.finished.info1',
                      { recordNumber: importedProgress.total - failedImportedRecords.length }
                    )}
                  </p>
                  <p>
                    {translate(
                      'wpone.domains.details.dnsRecords.importDnsRecordsDialog.finished.info2',
                      { recordNumber: failedImportedRecords.length }
                    )}
                  </p>

                  {failedImportedRecords.length > 0 && (
                    <>
                      <div className="gv-flex-column-sm gv-items-center" style={{ width: '100%' }}>
                        <p>
                          {translate(
                            'wpone.domains.details.dnsRecords.importDnsRecordsDialog.finished.info3'
                          )}
                        </p>

                        <Collapse in={isShowError} timeout="auto" unmountOnExit>
                          {failedImportedRecords.map((err, index) => (
                            <div
                              key={index}
                              className="gv-flex-column-sm gv-py-lg"
                              style={{
                                borderBottom:
                                  index !== failedImportedRecords.length - 1 ? '1px solid' : 'none',
                              }}
                            >
                              <p>
                                {translate(
                                  'wpone.domains.details.dnsRecords.importDnsRecordsDialog.finished.error.statusCode',
                                  { code: err.statusCode }
                                )}
                              </p>
                              <p>
                                {translate(
                                  'wpone.domains.details.dnsRecords.importDnsRecordsDialog.finished.error.message',
                                  { message: err.message }
                                )}
                              </p>
                              <p>
                                {translate(
                                  'wpone.domains.details.dnsRecords.importDnsRecordsDialog.finished.error.fileContent',
                                  { fileContent: err.fileContent }
                                )}
                              </p>
                            </div>
                          ))}
                        </Collapse>

                        {isShowError ? (
                          <IconWrapper
                            onClick={() => setIsShowError((prev) => !prev)}
                            style={{ cursor: 'pointer' }}
                          >
                            <gv-icon src={`/src/icons/keyboard_arrow_up.svg`} />
                          </IconWrapper>
                        ) : (
                          <IconWrapper
                            onClick={() => setIsShowError((prev) => !prev)}
                            style={{ cursor: 'pointer' }}
                          >
                            <gv-icon src={`/src/icons/keyboard_arrow_down.svg`} />
                          </IconWrapper>
                        )}
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>

          {/* Dialog header and footer style are set globally */}
          <Button
            text={translate('wpone.general.action.close')}
            uiType="cancel"
            onClick={onClose}
          />
        </>
      ) : (
        <>
          <div className="gv-modal-body">
            <h2 className="gv-modal-title">
              {translate('wpone.domains.details.dnsRecords.importDnsRecordsDialog.title')}
            </h2>

            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <div className="gv-flex-column-lg" style={{ overflowX: 'auto' }}>
                {!!errors.afterSubmit && (
                  <AlertBanner severity="error">{errors.afterSubmit.message}</AlertBanner>
                )}
                <p>
                  {translate('wpone.domains.details.dnsRecords.importDnsRecordsDialog.description')}
                </p>

                <RHFUploadSingleFile
                  name="recordFile"
                  maxSize={3145728}
                  onDropAccepted={handleDropAccepted}
                  onDropRejected={handleDropRejected}
                  onFileDialogOpen={handleClearPrevState}
                  onDragEnter={handleClearPrevState}
                  extraRejection={isRejected}
                />

                {recordArr.length > 0 && (
                  <div className="gv-flex-column-sm">
                    <p className="gv-text-bold">
                      {translate('wpone.domains.details.dnsRecords.importDnsRecordsDialog.info1', {
                        totalRecords: recordArr.length,
                      })}
                    </p>
                    <div className="gv-flex-column-sm gv-pl-sm">
                      {handleDisplayRecords(recordArr).map((recordType) => (
                        <div key={recordType.type} className="gv-flex" style={{ gap: '4px' }}>
                          <gv-icon src={`/src/icons/check.svg`} />
                          <p>
                            {translate(
                              'wpone.domains.details.dnsRecords.importDnsRecordsDialog.info2',
                              {
                                recordNumber: recordType.number,
                                type: recordType.type,
                              }
                            )}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <p className="gv-text-bold">
                  {translate('wpone.domains.details.dnsRecords.importDnsRecordsDialog.warning')}
                </p>
              </div>
            </FormProvider>
          </div>

          {/* Dialog header and footer style are set globally */}

          <ButtonGroup>
            <Button
              text={translate('wpone.general.action.cancel')}
              uiType="cancel"
              onClick={onClose}
            />
            <Button
              text={translate('wpone.domains.details.dnsRecords.importDnsRecordsDialog.action')}
              onClick={handleSubmit(onSubmit)}
              disabled={recordArr.length === 0 || isImporting}
            />
          </ButtonGroup>
        </>
      )}
    </>
  );
}
