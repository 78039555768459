import { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router';
import * as Yup from 'yup';
import ReactGA from 'react-ga4';
// redux
import { useDispatch } from 'src/redux/store';
import { useEditDnsRecordMutation } from 'src/redux/api/domainApi';
import { addRecordEditedDomain } from 'src/redux/features/domain';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @types
import {
  DnsRecordType,
  FormattedGetDNSRecordDTO,
  FormattedPatchDNSRecordDataBodyDTO,
  TTL,
} from 'src/@types/domain';
// hooks
import useLocales from 'src/hooks/useLocales';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
// utils
import { displayToast } from 'src/utils/handleToast';
import { fDnsRecordSubname } from 'src/utils/format';
// components
import { FormProvider, RHFSelect, RHFTextField, RHFToggle } from 'src/components/gravity/hook-form';
import ButtonGroup from 'src/components/gravity/ButtonGroup';
import Button from 'src/components/gravity/Button';
import Alert from 'src/components/gravity/Alert';

// ----------------------------------------------------------------------

type FormValuesProps = {
  recordType: string;
  hostname: string;
  cdn: boolean;
  pointsTo: string;
  ttl: TTL;
  afterSubmit?: string;
};

type Props = {
  isBunnyDns: boolean;
  editingRecord: FormattedGetDNSRecordDTO;
};

// ----------------------------------------------------------------------

export default function EditRecordCNAMEForm({ isBunnyDns, editingRecord }: Props) {
  const { name } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  // HOOK
  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  // API
  const [editDnsRecord, { isLoading }] = useEditDnsRecordMutation();

  // FORM
  const FormSchema = Yup.object().shape({
    pointsTo: Yup.string()
      .trim()
      .required(translate('wpone.domains.details.dnsRecords.form.pointsTo.validation')),
  });

  const defaultValues = {
    recordType: editingRecord.type,
    hostname: fDnsRecordSubname(editingRecord.name, name, isBunnyDns),
    cdn: editingRecord.proxied,
    pointsTo: editingRecord.content,
    ttl: editingRecord.ttl,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(FormSchema),
    defaultValues,
  });

  const {
    watch,
    setValue,
    setError,
    handleSubmit,
    resetField,
    formState: { errors },
  } = methods;

  const cdnValue = watch('cdn');

  // EVENT FUNCTION
  const onSubmit = async (data: FormValuesProps) => {
    if (!editingRecord || !name) return;

    ReactGA.event({
      category: 'button',
      action: 'click',
      label: `edit-dns-record`,
    });

    const updateDnsRecord: FormattedPatchDNSRecordDataBodyDTO = {
      type: data.recordType,
      name: data.hostname.trim() ? `${data.hostname.trim()}.${name}` : `${name}`,
      content: data.pointsTo.trim(),
      ttl: data.ttl,
    };

    // Only include the proxied key if its value has changed
    if (data.cdn !== editingRecord.proxied) {
      updateDnsRecord.proxied = data.cdn;
    }

    await editDnsRecord({
      domainName: name,
      dnsRecordId: editingRecord.id,
      updateDnsRecord,
      isBunnyDns: isBunnyDns,
    })
      .unwrap()
      .then((data) => {
        if (data) {
          if (isMountedRef.current) {
            navigate(`/dns/records/${name}`);
            dispatch(addRecordEditedDomain(name));
          } else {
            displayToast(
              translate(
                'wpone.domains.details.dnsRecords.editRecord.requestResponse.successMessage'
              )
            );
          }
        } else {
          throw new Error();
        }
      })
      .catch(() => {
        if (isMountedRef.current) {
          setError('afterSubmit', {
            message: translate(
              'wpone.domains.details.dnsRecords.editRecord.requestResponse.errorMessage'
            ),
          });
        } else {
          displayToast(
            translate('wpone.domains.details.dnsRecords.editRecord.requestResponse.errorMessage'),
            { variant: 'alert' }
          );
        }
      });
  };

  //
  useEffect(() => {
    if (!editingRecord || !name) return;

    if (cdnValue) {
      setValue('ttl', TTL.auto);

      // Only change pointsTo value if this is Bunny AND CDN is enabled
      if (isBunnyDns) {
        resetField('pointsTo');
        setValue('pointsTo', name.replace('.', '-') + '.b-cdn.net');
      }
    } else {
      setValue('ttl', editingRecord.ttl);

      if (isBunnyDns) {
        setValue('pointsTo', editingRecord.content);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cdnValue]);

  return (
    <>
      {!!errors.afterSubmit && <Alert type="alert" text={errors.afterSubmit.message} />}

      <FormProvider methods={methods}>
        <div className="gv-flex-column-md">
          <RHFSelect
            name="recordType"
            labelId="recordType"
            label={translate('wpone.domains.details.dnsRecords.form.recordType.label')}
            options={Object.values(DnsRecordType).map((type) => ({ label: type, value: type }))}
            disabled
          />

          <RHFTextField
            name="hostname"
            labelId="hostname"
            label={translate('wpone.domains.details.dnsRecords.form.hostname.label')}
            generalMsg={translate('wpone.domains.details.dnsRecords.form.hostname.message')}
            addonSuffix={
              <div className="gv-addon" style={{ maxWidth: '50%' }}>
                <p className="gv-text-truncate">{`.${name}`}</p>
              </div>
            }
            disabled={isBunnyDns}
          />

          <RHFToggle
            name="cdn"
            labelId="cdn"
            label={translate('wpone.domains.details.dnsRecords.form.cdn.label')}
            description={translate('wpone.domains.details.dnsRecords.form.cdn.description')}
            condensed
          />

          {/* Only change label from pointsTo to content if this is Bunny AND CDN is enabled */}
          <RHFTextField
            name="pointsTo"
            labelId="pointsTo"
            label={translate(
              `wpone.domains.details.dnsRecords.form.${
                isBunnyDns && cdnValue ? 'content' : 'pointsTo'
              }.label`
            )}
            generalMsg={translate('wpone.domains.details.dnsRecords.form.pointsTo.message')}
            disabled={isBunnyDns && cdnValue}
          />

          <RHFSelect
            name="ttl"
            labelId="ttl"
            label={translate('wpone.domains.details.dnsRecords.form.ttl.label')}
            disabled={cdnValue}
            options={Object.values(TTL).map((option) => ({
              value: option,
              label: translate(
                `wpone.domains.details.dnsRecords.form.ttl.options.${option.replace(' ', '')}`
              ),
            }))}
          />

          <ButtonGroup>
            <Button
              uiType="cancel"
              text={translate('wpone.general.action.cancel')}
              onClick={() => navigate(`/dns/records/${name}`)}
            />

            <Button
              text={translate('wpone.general.action.save')}
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
            />
          </ButtonGroup>
        </div>
      </FormProvider>
    </>
  );
}
